import { Col, Row, Button, message, Badge, Tooltip, Cascader, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
} from "../../constants";
import axios from "axios";
import { logOutUser } from "../../common";
import { useDebounce } from "../../common/debounce";
import moment from "moment-timezone";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const ListRadiusDevices = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [endpointData, setEndpointData] = useState([]);
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [osList, setOsList] = useState([]);
  const [sortModel, setSortModel] = useState();
  const debouncedValue = useDebounce(search, 500);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const { Text } = Typography;
  const [options, setOptions] = useState([]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions([]);
      return;
    }
    const filteredOptions = endpointData
      .filter((endpointdata) => endpointdata.hostName.toLowerCase().includes(value.toLowerCase()))
      .map((endpointdata) => ({
        label: endpointdata.hostName,
        value: endpointdata.hostName,
      }));

    setOptions(filteredOptions);
  };

  useEffect(() => {
    fetchInstance();
  }, [debouncedValue, filteredValue, paginationParams, currentPage]);

  const EndpointStatus = (lastUpdated) => {
    const lastActive = moment.unix(lastUpdated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
    const diff = moment().diff(lastActive, "minutes");
    let hours = Math.floor(diff / 60);
    let days = Math.floor(hours / 24);
    let minutes = diff % 60;

    let message;

    if (days > 0) {
      message = `Last Active: ${days} days ago`;
    } else if (hours > 0) {
      message = `Last Active: ${hours} hours${
        minutes > 0 ? ` and ${minutes} minutes ago` : " hours ago"
      }`;
    } else {
      message = `Last Active: ${diff} minutes ago`;
    }

    if (diff > 20) {
      return (
        <Tooltip title={message}>
          <Badge status="error" text="Offline" />
        </Tooltip>
      );
    } else if (diff > 10) {
      return (
        <Tooltip title={message}>
          <Badge status="warning" text="Offline" />
        </Tooltip>
      );
    }
    return <Badge status="success" text="Online" />;
  };

  const columns = [
    {
      headerName: "Host Name",
      field: "hostName",
      key: "hostName",
      flex: 1,
    },
    { headerName: "OS", field: "osname", key: "osname", flex: 1 },
    {
      headerName: "User Count",
      field: "countOfUsers",
      key: "countOfUsers",
      flex: 1,
    },

    {
      headerName: "Public IP",
      field: "publicIp",
      key: "publicIp",
      flex: 1,
    },

    {
      headerName: "State",
      field: "status",
      key: "status",
      flex: 1,
      renderCell: (val) => {
        let stateVal = val.row.status.toLowerCase() === "active" ? "Active" : "Inactive";
        return (
          <>
            <Text type={stateVal === "Active" ? "success" : "danger"}> {stateVal}</Text>
          </>
        );
      },
    },
    {
      headerName: "Agent Status",
      field: "AgentStatus",
      key: "status",
      flex: 1,
      renderCell: (val) => EndpointStatus(val.row.lastActive),
    },
  ];

  const handleSortModelChange = (model) => {
    setSortModel(model);
  };

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search: debouncedValue,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "radius",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        const originalEpm = res?.data?.instances;

        const epmWithId = originalEpm.map((epm, index) => ({
          ...epm,
          id: index + 1,
          countOfUsers: 0,
          countOfGroups: 0,
          osname: epm.osName,
        }));
        setEndpointData(epmWithId ?? []);
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });

        const fetchInstanceOsType = () => {
          let pageDetails = {
            tenantId: DOMAIN_ID(),
            orgId: ORG_ID(),
          };
          axios
            .post(`${REACT_APP_API_TENANTS_URL}/getOsList`, pageDetails, {
              headers: {
                withCredentials: true,
                "X-Authorization": AUTH_TOKEN(),
              },
            })
            .then(({ data }) => {
              const osData = data?.osList.map((os) => ({
                value: os.id.toString(),
                label:
                  os.osName.toLowerCase() === "windows"
                    ? "Windows"
                    : os.osName.toLowerCase() === "linux"
                    ? "Linux"
                    : os.osName,
              }));
              setOsList(osData);

              const dataWithOs = epmWithId.map((epm) => {
                const osItem = osData.find((os) => os.value === epm.osName);
                return {
                  ...epm,
                  osname: osItem ? osItem.label : epm.osName,
                };
              });
              setEndpointData(dataWithOs);
              const ids = originalEpm.map((item) => item.instanceId);

              const childApiCalls = ids.map((id) => {
                return Promise.all([fetchUserCount(id), fetchGroupCount(id)]);
              });

              // Wait for all child API calls to resolve
              Promise.all(childApiCalls)
                .then((results) => {
                  console.log("All child API calls completed", results);

                  // Update the epmWithId with the results from both user and group count
                  let updatedEpm = dataWithOs.map((epm, index) => {
                    const userCountData = results[index][0]?.data; // First item of Promise.all() result
                    const groupCountData = results[index][1]?.data; // Second item of Promise.all() result

                    return {
                      ...epm,
                      countOfUsers: userCountData?.count || 0,
                      countOfGroups: groupCountData?.count || 0,
                    };
                  });

                  setEndpointData(updatedEpm);
                })
                .catch((error) => {
                  console.error("Error in child API calls", error);
                });
            })
            .catch((err) => {
              if (
                err.response.data.message === "Invalid credentials" ||
                err?.response?.status === 401
              ) {
                notification.open({
                  type: "error",
                  content: "Credentials are invalid",
                });
                logOutUser();
              } else if (err.response.status === 401) {
                logOutUser();
              } else {
                notification.open({
                  type: "error",
                  content: "Unable to fetch OS",
                });
              }
            });
        };
        fetchInstanceOsType();
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchUserCount = async (id) => {
    let userCountRequest = {
      tenantId: DOMAIN_ID(),
      machineId: id,
      orgId: ORG_ID(),
      uuid: "",
    };

    return axios
      .post(`${REACT_APP_API_TENANTS_URL}/getCountofEpmUserByEndpoint`, userCountRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        console.log(childData);
        return childData; // Return the child data to be used in Promise.all
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };

  const fetchGroupCount = async (id) => {
    let userCountRequest = {
      tenantId: DOMAIN_ID(),
      machineId: id,
      orgId: ORG_ID(),
      uuid: "",
    };

    return axios
      .post(`${REACT_APP_API_TENANTS_URL}/getCountofEpmUserGroupByEndpoint`, userCountRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((childData) => {
        console.log(childData);
        return childData;
      })
      .catch((error) => {
        console.error("Error in child API call for ID", id, ":", error);
      });
  };

  const displayRender = (labels) => labels[labels.length - 1];

  const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const handleFilter = (_, data) => {
    console.log(data);
    setfilterBy(data ? data[0]?.value : "");
    setFilteredValue(data ? data[1]?.value : "");
    // setPaginationParams({ current: 1, pageSize: 10 });
    setCurrentPage(0);
  };

  const option = [
    {
      value: "State",
      label: "State",
      children: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
      ],
    },
    {
      value: "osname",
      label: "OS",
      children: osList,
    },
  ];

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Radius Devices</h2>
            </Col>
            <Col>
              <Link to={"/pam/radius/onboard"}>
                <Button type="primary">Add Radius Device</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-conatiner"
                  >
                    <Col span={6}>
                      <p className="search-label">Filter by</p>

                      <Cascader
                        options={option}
                        expandTrigger="hover"
                        displayRender={displayRender}
                        onChange={handleFilter}
                        placeholder="Search to select"
                        showSearch={{
                          filter,
                        }}
                        onClear={() => {
                          setfilterBy("");
                          setFilteredValue("");
                          setCurrentPage(0);
                        }}
                      />
                    </Col>
                    <Col span={6}>
                      <p className="search-label">Search</p>
                      <div>
                        <AutoComplete
                          placeholder="Search HostName"
                          allowClear
                          onSearch={handleSearch}
                          options={options}
                          onChange={(value) => {
                            setSearch(value);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DataGrid
                apiRef={apiRef}
                loading={isLoading}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                getRowId={(row) => row.instanceId}
                rows={endpointData}
                paginationMode="server"
                columns={columns}
                rowCount={totalCount}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                style={{ border: "none" }}
                page={currentPage}
                paginationModel={currentPageModel}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                onPaginationModelChange={(params) => {
                  setCurrentPage(params.page);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ListRadiusDevices;
