import React, { useEffect, useState } from "react";
import { Col, Row, Select, Button, Input, Form, message, Space, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import { logOutUser } from "../../../common";
import ReactJoyride from "react-joyride";

const AddPasswordPolicy = () => {
  const [serverHostName, setServerHostName] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [notification, setNotification] = message.useMessage();
  const [template, setTemplate] = useState([{ value: 0, label: "Default" }]);
  const [formData, setFormData] = useState({});
  const [checkPolicyName, setCheckPolicyName] = useState([]);
  const [epmGroup, setEpmGroup] = useState([]);
  const [endpointLength, setEndpointLength] = useState(false);
  const [endpointGrpLength, setEndpointGrpLength] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // const { mfa } = location.state;
  const queryParams = new URLSearchParams(location.search);
  const onBoardType = queryParams.get("type");
  const [isOnBoarding, setIsOnboarding] = useState(false);
  const [run, setRun] = useState(true);

  useEffect(() => {
    //TODO : Onboarding api call done here
    setIsOnboarding(onBoardType ? true : false);
  }, []);

  const startTour = () => {
    const tourStep = [
      {
        target: "#onboarding_step1",
        content: <span>Enter a unique policy name</span>,
        disableBeacon: true,
        // title: "MFA Device",
      },
      {
        target: "#onboarding_step2",
        content: <span>Select either multiple Endpoints or Endpoint Groups</span>,
        disableBeacon: true,
        // title: "Endpoint Authentication MFA",
      },
      {
        target: "#onboarding_step3",
        content: <span>Select any Rotation Time</span>,
        disableBeacon: true,
        // title: "Authentication Policy",
      },

      {
        target: "#onboarding_step4",
        content: <span>Click to save credential rotation policy for password</span>,
        disableBeacon: true,
        // title: "Endpoint Authentication MFA",
      },
    ];

    const handleTourCallback = ({ status }) => {
      if (status === "finished" || status === "skipped") {
        setRun(false);
      }
    };
    return (
      <ReactJoyride
        steps={tourStep}
        run={run}
        floaterProps={{ disableAnimation: true }}
        callback={handleTourCallback}
        continuous
        scrollToFirstStep
        disableScrollParentFix
        scrollOffset={100}
        showSkipButton
        showProgress
        hideCloseButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "blue",
            color: "#ffffff",
          },
          buttonBack: {
            color: "#1677ff",
          },
        }}
      />
    );
  };

  useEffect(() => {
    fetchInstance();
    fetchEndpointGroup();
    fetchCredRotationPolicy();
  }, []);

  useEffect(() => {
    const initialValues = {
      userType: "LDAP",
      rotationTime: 1,
      credentialType: "Web console only",
      template: "Default",
    };
    form.setFieldsValue(initialValues);
    setFormData({
      userType: "LDAP",
      rotationTime: "1 day",
      credentialType: "Web console only",
      template: "Default",
      instanceId: [],
      instanceGroupId: [],
    });
  }, []);

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      setEpmGroup(
        res?.data?.groups.map((grp) => ({
          value: grp.groupId,
          label: grp.groupName,
        }))
      );
    } catch (err) {
      if (err?.response?.data?.message === "Invalid credentials" || err?.response?.status === 401) {
        notification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };

  const fetchInstance = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setServerHostName(
          res.data.instances.map((ins) => ({
            value: ins.instanceId,
            label: (
              <div>
                {ins.hostName} <b>{`(${ins.publicIp})`}</b>
              </div>
            ),
            ipAddress: ins.publicIp,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const fetchCredRotationPolicy = (e) => {
    let requestData = {
      pageId: 1,
      pageSize: 100,
      search: "",
      credentialType: "PASSWORD",
      filter: {
        filterBy: "",
        value: "",
      },
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/listAllCredentialRotationPolicy`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let resData = res?.data?.credentialPolicies;
        const policy = resData?.map((data) => {
          return {
            value: data.policyId,
            label: data.policyName,
            ...data,
          };
        });

        setTemplate([...template, ...policy]);
        setCheckPolicyName(resData.map((data) => data.policyName));
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });

          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch policies",
          });
        }
      });
  };

  const userType = [
    { value: 1, label: "LDAP" },
    { value: 2, label: "Local user" },
  ];
  const rotateEvery = [
    { value: "1", label: "1 day", text: 1 },
    { value: "2", label: "Every week", text: 7 },
    { value: "3", label: "30 days", text: 30 },
    // { value: "4", label: "After each use", text: 0 },
  ];

  const handleUserType = (value, data) => {
    form.setFieldsValue({
      userType: data?.label,
    });
    setFormData({ ...formData, userType: value });
  };
  const handleTemplate = (value, data) => {
    setFormData({
      ...formData,
      template: value,
      userType: data.userType,
      rotationTime:
        data.rotationInDays === 1 ? "1 day" : data.rotationInDays === 30 ? "30 days" : "Every week",
      instanceId: data.endpoints.map((i) => ({
        value: i.instanceId,
        label: i.hostname,
      })),
      instanceGroupId: data.endpointGroups.map((i) => ({
        value: i.groupId,
        label: i.groupName,
      })),
    });
  };

  const handleRotate = (value, data) => {
    form.setFieldsValue({
      rotationTime: data?.text,
    });
    setFormData({ ...formData, rotationTime: value });
  };
  const handleCredential = (value, data) => {
    form.setFieldsValue({
      credentialType: data.label,
    });
    setFormData({ ...formData, credentialType: value });
  };
  const handleEndpoint = (value, data) => {
    console.log(value);
    if (value.length >= 1) {
      setEndpointLength(true);
    } else {
      setEndpointLength(false);
    }
    form.setFieldsValue({
      instanceId: value,
    });
    setFormData({ ...formData, instanceId: value });
  };
  const handleEndpointGrp = (value) => {
    if (value.length >= 1) {
      setEndpointGrpLength(true);
    } else {
      setEndpointGrpLength(false);
    }
    form.setFieldsValue({
      instanceGroupId: value,
    });
    setFormData({ ...formData, instanceGroupId: value });
  };

  const accessCredential = [
    { value: "1", label: "Web console only" },
    { value: "2", label: "Send to wallet" },
  ];

  const handleInstall = (e) => {
    let payload = {
      ...e,
      domainId: DOMAIN_ID(),
      instanceGroupId: e.instanceGroupId ? e.instanceGroupId : [],
      sessionExpiryTime: 15,
      credentialMedium: "",
      keyFormat: "",
      keyEncryption: "",
      publicKeyFolderPath: "",
      orgId: ORG_ID(),
      credentialType: "",
      instanceId: e.instanceId ? e.instanceId : [],
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/addCredentialRotationPolicy`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        navigate(-1);
        notification.open({
          type: "success",
          content: "Password add successfully!",
        });
      })
      .catch((err) => {
        setIsLoading(false);
        notification.open({
          type: "error",
          content: "Unable to add credential rotation policy password",
        });
      });
  };

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        {isOnBoarding && startTour()}
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col>
              <Tooltip title="The Newest policy will be overridden">
                <h2 className="title">Credential Rotation Policy Password</h2>
              </Tooltip>
            </Col>
          </Row>
          <Form form={form} layout="vertical" onFinish={handleInstall}>
            <Row align="middle" justify="space-between">
              <Col span={24}>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col span={8}>
                    <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.88)" }}>
                      Copy from Template
                    </p>
                    <Select
                      value={formData?.template}
                      placeholder="Select user type"
                      onChange={handleTemplate}
                      tokenSeparators={[","]}
                      showSearch
                      optionFilterProp="label"
                      options={template}
                    />{" "}
                  </Col>
                </Row>
                {/* <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Checkbox>Set as default for all endpoints</Checkbox>
                  </Col>
                </Row> */}

                <Row align="middle" justify="space-between">
                  <Col span={8} id="onboarding_step1">
                    <Form.Item
                      name="policyName"
                      label="Policy Name"
                      rules={[
                        {
                          required: true,
                          message: "Select Policy Name",
                        },
                        {
                          validator(rule, value) {
                            return new Promise((resolve, reject) => {
                              if (checkPolicyName.includes(value)) {
                                reject("Policy name already exist");
                              } else {
                                resolve();
                              }
                            });
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="userType"
                      label="User Type"
                      rules={[
                        {
                          required: true,
                          message: "Select User Type",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select user type"
                        onChange={handleUserType}
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={userType}
                        value={formData?.userType}
                      />{" "}
                    </Form.Item>
                  </Col>

                  <Col span={4}></Col>
                </Row>
                <Row align="middle" justify="space-between" id="onboarding_step2">
                  <Col span={8}>
                    <Form.Item
                      name="instanceId"
                      label="Endpoints"
                      rules={[
                        {
                          required: endpointGrpLength ? false : true,
                          message: "Select Endpoints",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select endpoints"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={serverHostName}
                        onChange={handleEndpoint}
                        disabled={endpointGrpLength && true}
                        value={formData?.instanceId}
                      />{" "}
                    </Form.Item>
                  </Col>
                  <Col span={8} id="onboarding_step2">
                    <Form.Item
                      name="instanceGroupId"
                      label="Endpoints Groups"
                      rules={[
                        {
                          required: endpointLength ? false : true,
                          message: "Select Endpoints Groups",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select endpoints groups"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={epmGroup}
                        onChange={(e) => handleEndpointGrp(e)}
                        disabled={endpointLength && true}
                        value={formData.instanceGroupId}
                      />{" "}
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item
                      name="credentialType"
                      label="Access Credential"
                      rules={[
                        {
                          required: true,
                          message: "Select Access Credential",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select access credential"
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={accessCredential}
                        onChange={handleCredential}
                        value={formData?.credentialType}
                        allowClear
                      />{" "}
                    </Form.Item>
                  </Col> */}
                  <Col span={4}></Col>
                </Row>
                <Row align="middle" justify="space-between">
                  <Col span={8} id="onboarding_step3">
                    <Form.Item
                      name="rotationTime"
                      label="Rotate Every"
                      rules={[
                        {
                          required: true,
                          message: "Select Rotate Every",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Rotate every"
                        onChange={handleRotate}
                        tokenSeparators={[","]}
                        showSearch
                        optionFilterProp="label"
                        options={rotateEvery}
                        value={formData?.rotationTime}
                      />{" "}
                    </Form.Item>
                  </Col>
                </Row>

                <Row justify="end">
                  <Col span={8}>
                    <Space>
                      <Button onClick={() => navigate(-1)}>Cancel</Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                        id="onboarding_step4"
                      >
                        Save
                      </Button>
                    </Space>
                  </Col>
                  <Col span={1}></Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddPasswordPolicy;
