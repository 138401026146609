import React, { useState, useEffect } from "react";
import {
  REACT_APP_API_SAM_URL,
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  DOMAIN_ID,
  ORG_ID,
} from "../../constants";
import { Button, Select, Row, Col, Typography } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../../styles/ServiceAccount.scss";

const { Title, Text } = Typography;
const { Option } = Select;
const ServiceAssignPermissions = ({ handleAssignPermissionData, isOnBoarding, startTour }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [endpointGroup, setEndpointGroup] = useState([]);
  const [endpoints, setEndpoints] = useState([]);
  const [endpointAdded, setEndpointAdded] = useState([]);
  const [endpointAddedFor, setEndpointAddedFor] = useState([]);
  const [assignFor, setAssignFor] = useState("endpoint");
  const [assignFrom, setAssignFrom] = useState("endpoint");
  const [groupsAdded, setGroupsAdded] = useState([]);
  const [groupsAddedFor, setGroupsAddedFor] = useState([]);
  const [serviceAccount, setServiceAccout] = useState([]);
  const [serviceAccountAdded, setServiceAccountAdded] = useState([]);

  useEffect(() => {
    fetchEndpointGroup();
    fetchEndpoints();
    fetchServiceaccounts();
  }, []);
  const fetchEndpoints = () => {
    const userId = localStorage.getItem("userId");

    let data = {
      pageId: 1,
      pageSize: 100,
      filter: {
        filterBy: "",
        value: "",
      },
      search: "",
      userId: parseInt(userId),
      token: AUTH_TOKEN(),
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/instances/list`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpoints(res?.data?.instances);
      })
      .catch((err) => {});
  };
  const fetchServiceaccounts = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
      pageNo: 1,
      pageSize: 100,
      filter: {
        filterBy: "",
        Value: "",
      },
      search: "",
    };

    axios
      .post(`${REACT_APP_API_SAM_URL}/getAll`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setServiceAccout(res.data.data);

        console.log(res);
      })
      .catch((err) => {});
  };
  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      orgId: ORG_ID(),
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      filter: {
        filterBy: "",
        value: "",
      },
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/instanceGroup/listEndpointGroup`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setEndpointGroup(
          res?.data?.groups?.map((grp, i) => ({
            value: grp.groupId,
            label: grp.groupName,
          }))
        );
      })

      .catch((err) => {});
  };
  const AddService = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      serviceAccountId: serviceAccountAdded,
      access: true,
      sourceType: assignFrom,
      source: assignFrom === "endpoint" ? endpointAdded : groupsAdded,
      destinationType: assignFor,
      destination: assignFor === "endpoint" ? endpointAddedFor : groupsAddedFor,
    };
    handleAssignPermissionData(pageDetails);
  };

  return (
    <>
      {isOnBoarding && startTour()}

      <div className="rule-container">
        <Row style={{ paddingRight: "3rem" }}>
          <Col span={12}>
            <h3>Let Set this Up</h3>
            <Row>
              <Col span={24}>
                <p>Assign Permissions from</p>
                <Select
                  value={assignFrom}
                  placeholder="Select Identity Group"
                  options={[
                    { label: "Endpoint Groups", value: "endpointGroup" },
                    { label: "Endpoints", value: "endpoint" },
                  ]}
                  allowClear
                  onChange={(e) => setAssignFrom(e)}
                />
              </Col>
            </Row>
            {assignFrom === "endpointGroup" ? (
              <Row>
                <Col span={24}>
                  <p>Endpoint Groups</p>
                  <Select
                    mode="multiple"
                    onChange={(e, val) => setGroupsAdded(e)}
                    allowClear
                    options={endpointGroup}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <p>Endpoints</p>
                  <Select mode="multiple" onChange={(e) => setEndpointAdded(e)}>
                    {endpoints &&
                      endpoints.length > 0 &&
                      endpoints.map((group, index) => (
                        <Option key={index} value={group.instanceId}>
                          {group.hostName}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            )}
            <Row></Row>
            <Row>
              <Col span={24}>
                <p>Service Account</p>
                <Select allowClear onChange={(e) => setServiceAccountAdded(e)}>
                  {serviceAccount &&
                    serviceAccount.length > 0 &&
                    serviceAccount.map((group, index) => (
                      <Option key={index} value={group.id}>
                        {group.username}
                      </Option>
                    ))}
                </Select>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <p>Assign Permissions for</p>
                <Select
                  value={assignFor}
                  placeholder="Select Identity Group"
                  options={[
                    { label: "Endpoint Groups", value: "endpointGroup" },
                    { label: "Endpoints", value: "endpoint" },
                  ]}
                  allowClear
                  onChange={(e) => setAssignFor(e)}
                />
              </Col>
            </Row>
            {assignFor === "endpointGroup" ? (
              <Row>
                <Col span={24}>
                  <p>Endpoint Groups</p>
                  <Select
                    mode="multiple"
                    onChange={(e) => {
                      setGroupsAddedFor(e);
                    }}
                    allowClear
                    options={endpointGroup}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <p>Endpoints</p>
                  <Select mode="multiple" onChange={(e) => setEndpointAddedFor(e)}>
                    {endpoints &&
                      endpoints.length > 0 &&
                      endpoints.map((group, index) => (
                        <Option key={index} value={group.instanceId}>
                          {group.hostName}
                        </Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col span={6}></Col>
              <Col
                span={6}
                style={{
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                  marginBottom: "3rem",

                  display: "flex",
                }}
              >
                <Button onClick={() => AddService()} type="primary">
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceAssignPermissions;
