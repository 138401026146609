import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tag,
  message,
  Drawer,
  Tooltip,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API } from "../../constants";
import { useDebounce } from "../../common/debounce";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { EditOutlined, InboxOutlined, InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import ReactJson from "@microlink/react-json-view";

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);

const statusOptions = [
  { label: "Approved", value: "Approved" },
  { label: "Revoked", value: "Revoked" },
  { label: "Pending", value: "Pending" },
  { label: "Deprecated", value: "Deprecated" },
];

const policyTypesOptions = [
  { label: "Endpoints", value: "endpoints" },
  { label: "Active Directory", value: "AD" },
  { label: "Radius", value: "radius" },
  // { label: "Autogenerated", value: "autogenerated" },
  // { label: "Database", value: "database" },
];

const JsonCellRenderer = ({ value }) => (
  <ReactJson
    src={value}
    name={false}
    collapsed={false}
    theme={"rjv-default"}
    enableClipboard={false}
    enableEdit={false}
    enableAdd={false}
    enableDelete={false}
    displayDataTypes={false}
    displayObjectSize={false}
    displayArrayKey={false}
  />
);

export default function Policies() {
  const [notification, notificationHolder] = message.useMessage();
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce(search, 500);
  const [viewPolicyDrawer, setViewPolicyDrawer] = useState(false);
  const [policyJsonData, setPolicyJsonData] = useState();

  const [openApprovePolicyModal, setOpenApprovePolicyModal] = useState(false);
  const [selectedPolicyRow, setSelectedPolicyRow] = useState(null);
  const [openDeletePolicyModal, setOpenDeletePolicyModal] = useState(false);

  //pagination
  const [paginationParams, setPaginationParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [totalCount, setTotalCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: 10,
    page: 0,
  });

  useEffect(() => {
    fetchPolicyData();
  }, [appliedFilters, debouncedValue, currentPage]);

  const fetchPolicyData = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      requestId: "", // DOUBT: for backend use only.
      limit: paginationParams.pageSize,
      page: paginationParams.current,
      search: debouncedValue ? debouncedValue : "",
      ...(Object.keys(appliedFilters).length > 0 && {
        filters: Object.entries(appliedFilters).map(([key, value]) => ({
          filterParameter: key,
          filterValue: value,
        })),
      }),
    };
    setIsLoading(true);
    try {
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/ListPolicyV2`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setPolicies(res?.data?.data);
      setTotalCount(res?.data?.total_pages);
      setCurrentPageModel({
        pageSize: 10,
        page: currentPage,
      });
    } catch (error) {
      notification.open({
        type: "error",
        content: "Failed to load policies",
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      headerName: "",
      field: "updatePolicy",
      flex: 0.2,
      renderCell: (params) => (
        <Link style={{ cursor: "pointer" }} onClick={() => {}}>
          <EditOutlined />
        </Link>
      ),
    },
    {
      headerName: "Policy Name",
      field: "policyName",
      flex: 1.2,
      renderCell: (val) => (
        <a
          onClick={() => {
            setViewPolicyDrawer(true);
            setPolicyJsonData(val?.row?.policyJson);
          }}
        >
          {val?.row?.policyName}
        </a>
      ),
    },
    {
      headerName: "Type",
      field: "policyType",
      flex: 1,
      renderCell: (val) => <Tag>{val?.row?.policyType}</Tag>,
    },
    {
      headerName: "IAM Users",
      field: "endpointUsers",
      flex: 2.3,
      renderCell: (params) => (
        <div>
          {params.row.policyJson?.iam?.iam_users?.map((user, index) => (
            <Tag key={index}>{user}</Tag>
          )) || "No users"}
        </div>
      ),
    },
    {
      headerName: "Policy Action",
      field: "allowed",
      flex: 1.2,
      renderCell: (params) => (
        <Tag color={params.row.policyJson?.permissions?.allowed ? "green" : "red"}>
          {params.row.policyJson?.permissions?.allowed ? "Allowed" : "Not Allowed"}
        </Tag>
      ),
    },
    {
      headerName: (
        <div>
          <span style={{ marginRight: ".5rem" }}>Status</span>
          <Tooltip title="Status of the policy can be pending, Active,Inactive or Deprecated">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      field: "status",
      flex: 1,
      renderCell: (val) => (
        <Tag
          color={
            val?.row?.status === "Approved"
              ? "green"
              : val?.row?.status === "Pending"
              ? "orange"
              : val?.row?.status === "Revoked"
              ? "red"
              : val?.row?.status === "Deprecated"
              ? "gray"
              : "default"
          }
        >
          {val?.row?.status}
        </Tag>
      ),
    },
    {
      headerName: "Version",
      field: "version",
      flex: 0.8,
    },
    {
      headerName: "Updated At",
      field: "updatedAt",
      flex: 2,
      renderCell: (val) =>
        moment(val?.row?.updatedAt).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm:ss [PST]"),
    },
    {
      headerName: "Action",
      field: "action",
      flex: 1,
      renderCell: (val) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <a
                  onClick={() => {
                    setViewPolicyDrawer(true);
                    setPolicyJsonData(val?.row?.policyJson);
                  }}
                >
                  View JSON
                </a>
              </Menu.Item>

              <Menu.Item key="2">
                <a
                  onClick={() => {
                    setOpenApprovePolicyModal(true);
                    setSelectedPolicyRow(val.row);
                  }}
                >
                  {val?.row?.status === "Approved" ? "Revoke" : "Approve"}
                </a>
              </Menu.Item>

              <Menu.Item key="3">
                <a onClick={() => {}}>History</a>
              </Menu.Item>

              <Menu.Item key="4">
                <a
                  onClick={() => {
                    setSelectedPolicyRow(val.row);
                    setOpenDeletePolicyModal(true);
                  }}
                >
                  Delete
                </a>
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },
  ];

  const handleModalClose = () => {
    setSelectedPolicyRow(null);
    setOpenApprovePolicyModal(false);
  };

  const handlePolicyApproval = () => {
    fetchPolicyData(); // Reload data after policy approval
  };

  const applyFilter = (type, value) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
    setCurrentPage(0);
  };

  const getAppliedFilterValue = (type) => {
    return appliedFilters[type] ?? null;
  };

  return (
    <>
      {notificationHolder}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Policies</h2>
            </Col>
            <Col>
              <Link to={"/pam/policy/v2/createPolicy"}>
                <Button type="primary">Add Policy</Button>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1rem" }} gutter={20}>
            <Col span={4}>
              <p className="search-label">Search</p>
              <Input
                type="text"
                placeholder="Search policy"
                onChange={(event) => setSearch(event.target.value)}
                allowClear
              />
            </Col>
            <Col span={4}>
              <p className="search-label">Policy Type</p>
              <Select
                placeholder="Select status"
                onChange={(val) => applyFilter("policyType", val)}
                value={getAppliedFilterValue("policyType")}
              >
                {policyTypesOptions.map((type) => (
                  <Select.Option value={type.value}>{type.label}</Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={4}>
              <p className="search-label">Status</p>
              <Select
                placeholder="Select status"
                onChange={(val) => applyFilter("status", val)}
                value={getAppliedFilterValue("status")}
              >
                {statusOptions.map((status) => (
                  <Select.Option value={status.value}>{status.label}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row style={{ marginBottom: "2rem" }}>
            <Col>
              {Object.keys(appliedFilters).length > 0 && (
                <p className="mb-2">
                  Applied Filters:{" "}
                  <Link
                    href="#"
                    onClick={() => setAppliedFilters([])}
                    style={{ marginLeft: "1rem", color: "#1890ff" }}
                  >
                    Clear filters
                  </Link>
                </p>
              )}

              {Object.entries(appliedFilters).map(([key, value]) => {
                if (value) {
                  const capitalize = (text) =>
                    text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

                  return (
                    <Tag
                      key={key}
                      closable={true}
                      onClose={() => {
                        setAppliedFilters((prevFilters) => {
                          let tempFilters = { ...prevFilters };
                          delete tempFilters[key];
                          return tempFilters;
                        });
                      }}
                    >
                      {`${capitalize(key)}: ${capitalize(value)}`}
                    </Tag>
                  );
                }
                return null;
              })}
            </Col>
          </Row>

          <Row>
            <DataGrid
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
              rows={policies}
              rowCount={totalCount}
              columns={columns}
              style={{ border: "none", width: "100%", overflow: "auto" }}
              loading={isLoading}
              page={currentPage}
              paginationMode="server"
              paginationModel={currentPageModel}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              onPaginationModelChange={(params) => {
                setCurrentPage(params.page);
              }}
            />
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Policy JSON"
        onClose={() => setViewPolicyDrawer(false)}
        open={viewPolicyDrawer}
      >
        <JsonCellRenderer value={policyJsonData} />
      </Drawer>

      <ApprovePolicyModal
        isModelOpen={openApprovePolicyModal}
        handleModalClose={handleModalClose}
        onPolicyApproved={handlePolicyApproval}
        selectedPolicyRow={selectedPolicyRow}
      />

      <DeletePolicyModal
        isModelOpen={openDeletePolicyModal}
        handleModalClose={() => {
          setOpenDeletePolicyModal(false);
        }}
        policyId={selectedPolicyRow?.id}
        onPolicyApproved={handlePolicyApproval}
      />
    </>
  );
}

const ApprovePolicyModal = ({
  isModelOpen,
  handleModalClose,
  onPolicyApproved,
  selectedPolicyRow,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleApprove = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/approvePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy approved successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to approve policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleRevoke = async () => {
    setIsLoading(true);
    const payload = {
      policyId: selectedPolicyRow?.id,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/revokePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy revoked successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to revoke policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title={
        selectedPolicyRow?.status === "Approved" ? "Revoke Policy Credentails" : "Approve Policy"
      }
      okText={selectedPolicyRow?.status === "Approved" ? "Revoke" : "Approve"}
      onOk={selectedPolicyRow?.status === "Approved" ? handleRevoke : handleApprove}
      width={800}
      okButtonProps={{ loading: isLoading }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Name</p>
          <Input value={selectedPolicyRow?.policyName} disabled />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Policy Type</p>
          <Input value={selectedPolicyRow?.policyType} disabled />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>IAM Users</p>
          <Select
            value={selectedPolicyRow?.policyJson?.iam?.iam_users}
            mode="multiple"
            disabled
            style={{ width: "100%" }}
          />
        </Col>

        <Col span={12}>
          <p style={{ marginBottom: "0.3rem" }}>Status</p>
          <Input value={selectedPolicyRow?.status} disabled />
        </Col>
      </Row>

      <Col>
        <p style={{ marginBottom: "0.3rem" }}>Policy JSON</p>
        <JsonCellRenderer value={selectedPolicyRow?.policyJson} />
      </Col>
    </Modal>
  );
};

const DeletePolicyModal = ({ isModelOpen, handleModalClose, onPolicyApproved, policyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = async () => {
    setIsLoading(true);
    const payload = {
      policyId: policyId,
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/deletePolicyJSON`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(() => {
        message.success("Policy deleted successfully");
        onPolicyApproved();
        handleModalClose();
        setIsLoading(false);
      })
      .catch((error) => {
        message.error("Failed to delete policy");
        console.error(error);
        setIsLoading(false);
      });
  };

  return (
    <Modal
      open={isModelOpen}
      onCancel={handleModalClose}
      title="Delete Policy"
      okText="Delete"
      width={500}
      onOk={handleDelete}
      okButtonProps={{ loading: isLoading }}
    >
      <p>Are you sure you want to delete this policy?</p>
    </Modal>
  );
};
