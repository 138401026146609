import React, { useEffect, useState } from "react";

import {
  Col,
  Row,
  Input,
  Tag,
  Select,
  DatePicker,
  notification,
  message,
  Typography,
  Dropdown,
  Button,
  Badge,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import { DataGrid, GridOverlay, useGridApiRef } from "@mui/x-data-grid";
import { InboxOutlined, MoreOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  AUTH_TOKEN,
  DOMAIN_ID,
  ORG_ID,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_API_TENANTS_URL,
  REACT_APP_PAM_API,
} from "../../../constants";
import moment from "moment-timezone";
import { GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import ApproveRequest from "./ApproveRequest";
import { withSuccess } from "antd/es/modal/confirm";
import ApproveRequestV1 from "./ApproveRequestV1";

import InfiniteScrollDropdown from "../../../common/InfiniteScrollDropdown";

import { useDebounce } from "../../../common/debounce";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const CustomNoRowsOverlay = () => (
  <GridOverlay>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InboxOutlined style={{ fontSize: "24px", color: "#999" }} />
      <span>No Data</span>
    </div>
  </GridOverlay>
);
const Request = () => {
  const [sortModel, setSortModel] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPageModal, setCurrentPageModal] = useState({
    pageSize: 100,
    page: 0,
  });
  const [appliedFilters, setAppliedFilters] = useState([
    { filterParameter: "logType", filterValue: "AD" },
  ]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [endpointGroups, setEndpointGroups] = useState([]);
  const [user, setUser] = useState([]);
  const [endpointData, setEndpointData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [ouData, setOuData] = useState([]);
  const [isNotification, setNotification] = message.useMessage();
  const [states, setStates] = useState("Rejected");
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  const [userType, setUserType] = useState("AD");
  const [tenantData, setTenantData] = useState([]);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [options, setOptions] = useState([]);

  const [search, setSearch] = useState("");

  const debouncedValue = useDebounce(search, 500);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchRequest();
    fetchTenantDetails();

    return () => {
      message.destroy("permanentMessage");
    };
  }, [appliedFilters, sortModel, userType]);
  useEffect(() => {
    fetchEndpointGroup();
    fetchAdUser();
    listDomain();
  }, []);

  useEffect(() => {
    fetchInstance();
  }, [debouncedValue]);

  const handleScroll = (event) => {
    const { target } = event;

    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 10 && !loading) {
      fetchInstance();
    }
  };

  const fetchInstance = () => {
    setLoading(true);

    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: page,
      pageSize: 10,
      search: debouncedValue,
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };

    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const Instances = res?.data?.instances || [];

        if (Instances.length > 0) {
          const mappedInstances = Instances.map((instance) => ({
            label: (
              <>
                {instance.hostName} <b>({instance.publicIp})</b>
              </>
            ),
            value: instance.hostName,
            id: instance.instanceId,
          }));

          setOptions((prevData) => {
            const newData = mappedInstances.filter(
              (newInstance) =>
                !prevData.some((existingInstance) => existingInstance.value === newInstance.value)
            );
            return [...prevData, ...newData];
          });

          setPage((prevPage) => prevPage + 1);

          if (Instances.length < pageDetails.pageSize) {
            setHasMore(false);
          }
        } else {
          // If no instances are returned, set hasMore to false
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchTenantDetails = () => {
    let tenantPayload = {
      email: localStorage.getItem("UserName"),
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getTenantDetail`, tenantPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setTenantData(res.data.data);
        const PolicyType = res.data.data.authenticationpolicy;
        message.open({
          type: PolicyType === 1 ? "info" : PolicyType === 2 ? "warning" : "success",
          content: `Tenant has policies in ${
            PolicyType === 1 ? "audit" : PolicyType === 2 ? "policy" : "live"
          } mode`,
          key: "permanentMessage",
          duration: 0, // Set duration to 0 for a permanent message
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          },
        });
      });
  };

  const fetchAdUser = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/users/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setUser(res?.data?.users);
      })
      .catch((err) => {
        if (err.response.data.message === "Invalid credentials") {
          // logOutUser();
          isNotification.open({
            type: "error",
            content: "Credentials are invalid",
          });
        } else {
          isNotification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };
  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");
    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 100,
      token: AUTH_TOKEN(),
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      const originalGroups = res?.data?.groups;

      // Adding an 'id' property to each object
      const groupsWithId = originalGroups.map((group, index) => ({
        ...group,
        id: index + 1, // You can use a different logic to generate IDs if needed
      }));
      setEndpointGroups(groupsWithId);
    } catch (err) {
      if (err?.response?.data?.message === "Invalid credentials" || err?.response?.status === 401) {
        isNotification.open({
          type: "error",
          content: "Credentials are invalid",
        });
        // logOutUser();
      } else {
        isNotification.open({
          type: "error",
          content: "Error fetching Endpoint Groups",
        });
      }
    }
  };

  const fetchRequest = (page) => {
    let REQUEST_HEADER;
    let requestPayload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyType: userType ? userType : "AD",
      filters: appliedFilters ? appliedFilters : [],
      requestId: "",
      page: page,
      limit: currentPageModal.pageSize,
      sort: {
        field: sortModel ? sortModel[0]?.field : "",
        order: sortModel ? sortModel[0]?.sort : "",
      },
    };
    if (appliedFilters.length !== 0) {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/FilterAccessRequest`;
    } else {
      REQUEST_HEADER = `${REACT_APP_API_DID_URL}/policyService/AccessRequest`;
    }
    axios
      .post(REQUEST_HEADER, requestPayload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then(({ data }) => {
        setRequestData(
          data?.data?.map((res, i) => ({
            id: i + 1,
            ...res,
          }))
        );
        setTotalCount(data.total_pages * 100);
        setCurrentPageModal({
          pageSize: 100,
          page: data.page - 1,
        });
      })
      .catch((err) => {});
  };

  const handleSortModelChange = (val) => {
    setSortModel(val);
  };

  const listDomain = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "ad-domains",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        const inputArray = res.data.data.adDomains;
        setDomainData((prevData) => [...prevData, ...inputArray]);
      });
  };

  const handleArchive = (val) => {
    let payload = {
      orgId: val?.orgId,
      tenantId: val?.tenantId,
      logType: val?.logType,
      os: val?.os,
      adUser: val?.adUser,
      adDomain: val?.adDomain,
      adOu: val?.adOu,
      sourceEndpoint: val?.sourceEndpoint,
      destinationEndpoint: val?.destinationEndpoint,
      destinationEndpointIp: val?.destinationEndpointIp,
      username: val?.username,
      accessMode: val?.accessMode,
      protocol: val?.protocol,
      timestamp: val?.timestamp,
      loginStatus: val?.loginStatus,
      samAccountName: val?.samAccountName,
      platformUser: val?.walletUser,
      requestCount: val?.requestCount,
      walletUser: val?.walletUser,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/ArchiveRequest`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })

      .then((res) => {
        isNotification.open({
          type: "success",
          content: `Successfully ${
            val.walletUser ? val.walletUser : val?.adUser || val?.username
          } archived`,
        });
        fetchRequest();
      })
      .catch((err) => {
        isNotification.open({
          type: "error",
          content: `Unable to archive ${val.walletUser}`,
        });
      });
  };
  const columns = [
    {
      headerName: "", // Empty header for the expand/collapse column
      field: "expand",
      width: 10,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleRowExpand(params);
            showModal();
          }}
        >
          {isModalVisible ? "[-]" : "[+]"}
        </Link>
      ),
    },
    {
      headerName: "Wallet User",
      field: "walletUser",
      width: 190,
      minWidth: 150,
      maxWidth: 500,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.walletUser?.split(",");

        return (
          <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
            {tagsArray?.map((tag, index) => (
              <Tag key={index} onClick={() => handleApplyFilter("walletUser", tag)}>
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    userType === "AD"
      ? {
          headerName: "Directory User",
          field: "adUser",
          width: 150,
          minWidth: 150,
          maxWidth: 500,
          sortable: true,
          renderCell: (val) => {
            const tagsArray = val?.row?.adUser?.split(",");

            return (
              <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
                {tagsArray?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleApplyFilter("username", tag)}>
                    {tag.trim()}
                  </Tag>
                ))}
              </div>
            );
          },
        }
      : userType === "ServiceAccount"
      ? {
          headerName: "Service Account Name",
          field: "username",
          width: 150,
          minWidth: 180,
          maxWidth: 500,
          sortable: true,
          renderCell: (val) => {
            const tagsArray = val?.row?.username?.split(",");

            return (
              <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
                {tagsArray?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleApplyFilter("username", tag)}>
                    {tag.trim()}
                  </Tag>
                ))}
              </div>
            );
          },
        }
      : {
          headerName: "Login User",
          field: "username",
          width: 150,
          minWidth: 150,
          maxWidth: 500,
          sortable: true,
          renderCell: (val) => {
            const tagsArray = val?.row?.username?.split(",");

            return (
              <div style={{ cursor: "pointer", whiteSpace: "normal" }}>
                {tagsArray?.map((tag, index) => (
                  <Tag key={index} onClick={() => handleApplyFilter("username", tag)}>
                    {tag.trim()}
                  </Tag>
                ))}
              </div>
            );
          },
        },
    ...(userType === "ServiceAccount"
      ? [
          {
            headerName: "Source Hostname",
            field: "sourceEndpoint",
            width: 50,
            minWidth: 200,
            maxWidth: 400,
            sortable: true,
            renderCell: (val) => {
              const tagsArray = val?.row?.sourceEndpoint?.split(","); //NOTE : SOURCE HOSTNAME IS NOT AVAILABLE IN THE RESPONSE
              return (
                <div style={{ whiteSpace: "normal" }}>
                  {tagsArray?.map((tag, index) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleApplyFilter("sourceEndpoint", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))}
                </div>
              );
            },
          },
          {
            headerName: "Source IP",
            field: "sourceEndpointIP",
            width: 50,
            minWidth: 150,
            maxWidth: 500,
            sortable: true,
            renderCell: (val) => {
              const tagsArray = val?.row?.sourceEndpointIP?.split(",");

              return (
                <div style={{ whiteSpace: "normal" }}>
                  {tagsArray?.map((tag, index) => (
                    <Tag
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => handleApplyFilter("sourceEndpointIP", tag)}
                    >
                      {tag.trim()}
                    </Tag>
                  ))}
                </div>
              );
            },
          },
        ]
      : []),
    {
      headerName: "Destination Hostname",
      field: "destinationEndpoint",
      width: 50,
      minWidth: 200,
      maxWidth: 400,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.destinationEndpoint?.split(",");

        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray?.map((tag, index) => (
              <Tag
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => handleApplyFilter("destinationEndpoint", tag)}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      headerName: "Destination IP",
      field: "destinationEndpointIp",
      width: 50,
      minWidth: 150,
      maxWidth: 500,
      sortable: true,
      renderCell: (val) => {
        const tagsArray = val?.row?.destinationEndpointIp?.split(",");
        // let values = "bijay@kloudone.com,hema@kloudone.com,muthu@kloudone.com";
        // const tagsArray = values?.split(",");
        return (
          <div style={{ whiteSpace: "normal" }}>
            {tagsArray?.map((tag, index) => (
              <Tag
                style={{ cursor: "pointer" }}
                key={index}
                onClick={() => handleApplyFilter("destinationEndpointIp", tag)}
              >
                {tag.trim()}
              </Tag>
            ))}
          </div>
        );
      },
    },

    {
      headerName: "Auth Count",
      field: "requestCount",
      width: 50,
      minWidth: 100,
      maxWidth: 300,
      sortable: true,
      renderCell: (val) => (
        <>
          <span>{val?.row?.requestCount}</span>
        </>
      ),
    },
    {
      headerName: "Policy Search",
      field: "accessMode",
      width: 130,
      minWidth: 130,
      maxWidth: 500,
      sortable: true,
      renderCell: (val) => (
        <>
          <Link
            to={`/pam/policy`}
            state={{
              destinationEndpointIp: val?.row?.destinationEndpointIp,
              destinationEndpoint: val?.row?.destinationEndpoint,
              walletUser: val?.row?.walletUser,
              adUser: val?.row?.adUser,
              logType:
                val?.row?.logType.toLowerCase() === "epm"
                  ? "local"
                  : val?.row?.logType?.toLowerCase() === "serviceaccount"
                  ? "ServiceAccount"
                  : "ad",
              endpointUser: val?.row?.username,
            }}
          >
            View
          </Link>
        </>
      ),
    },
    {
      headerName: "Action",
      field: "action",
      key: "action",
      width: 70,
      renderCell: (val) => (
        <Dropdown
          placement="bottomLeft"
          menu={{
            items: [
              // {
              //   key: "create policy",
              //   label: <Link onClick={() => showModal()}>Create Policy</Link>,
              // },
              {
                key: "archive",
                label: (
                  <Link
                    onClick={() => {
                      handleArchive(val?.row);
                    }}
                  >
                    Archive
                  </Link>
                ),
              },
            ],
          }}
        >
          <Button type="text" shape="circle" icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const handleApplyFilter = (filter, value) => {
    let filterParameter = filter;
    let filterValue = value;
    let obj = [
      {
        filterParameter: filterParameter,
        filterValue: filterValue,
      },
    ];
    let tempAppliedFilter = appliedFilters;
    let tempfilter = tempAppliedFilter.concat(obj);
    const uniqueFilters = tempfilter.reduceRight((acc, current) => {
      const existingFilter = acc.find(
        (filter) => filter.filterParameter === current.filterParameter
      );

      if (!existingFilter) {
        acc.push(current);
      }

      return acc;
    }, []);
    setAppliedFilters(uniqueFilters);
  };

  const handleRemoveFilter = (filter, value) => {
    if (filter === "logType") {
      setUserType("AD");
    }

    setAppliedFilters(
      appliedFilters.filter(
        (filterItem) => filterItem.filterParameter !== filter || filterItem.filterValue !== value
      )
    );
  };

  const getCurrentOption = (type) => {
    const currentOption = appliedFilters.filter((opt) => opt.filterParameter === type);
    return currentOption[0]?.filterValue ?? null;
  };

  const handleRowExpand = (params) => {
    setSelectedRow(params?.row);
    setExpandedRows((prevRows) =>
      prevRows.includes(params?.id?.toString())
        ? prevRows.filter((row) => row !== params?.id?.toString())
        : [...prevRows, params.id.toString()]
    );
  };

  const isRowExpandable = (params) => {
    // Example condition for expandable rows
    return true;
  };

  const handleExpandSave = () => {
    const requestId = selectedRow.id;
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      requestId: requestId.toString(),
      status: states,
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/policyService/UpdateUserAccessRequest`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsModalVisible(false);
        fetchRequest();
        setTimeout(() => {
          isNotification.open({
            type: "success",
            content: `${selectedRow?.adUser ?? selectedRow?.endpointUser} Request ${
              states === "Rejected" ? "rejected" : "approved"
            } successfully`,
          });
        });
      }, 3000)
      .catch((err) => {
        setTimeout(() => {
          isNotification.open({
            type: "error",
            content: "Unable to update the status",
          });
        }, 3000);
      });
  };

  // const { handleScroll } = useInfiniteScroll(fetchInstance, loading, hasMore);

  return (
    <>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h2 className="title">Requests</h2>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Row
                    style={{ marginBottom: "2rem", gap: "2rem" }}
                    className="search-box-container"
                  >
                    <Col span={4}>
                      <p className="search-label">Access Type</p>
                      <Select
                        style={{ width: 200 }}
                        placeholder="Search Access Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          handleApplyFilter("logType", e);
                          setUserType(e);
                        }}
                        // onDeselect={(e) => handleRemoveFilter("policyType", e)}
                        value={getCurrentOption("logType")}
                        options={[
                          { value: "EPM", label: "Endpoint User" },
                          {
                            value: "AD",
                            label: "Active Directory",
                          },
                          {
                            value: "ServiceAccount",
                            label: "Service Account",
                          },
                        ]}
                      />
                    </Col>

                    {userType === "AD" && (
                      <>
                        <Col span={4}>
                          <p className="search-label">Domain</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search Domain"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={getCurrentOption("adDomain")}
                            onChange={(e, val) => {
                              handleApplyFilter("adDomain", val?.children);
                              const selectedDomain = domainData.find((domain) => domain.id === e);
                              if (selectedDomain) {
                                setOuData(
                                  selectedDomain?.ous?.map((data) => {
                                    let ouVal = data.OuName.split(",")[0];
                                    let nameOu = ouVal.substring(3);
                                    return { ...data, ouNames: nameOu };
                                  })
                                );
                              } else {
                                setOuData([]);
                              }
                            }}
                          >
                            {domainData?.map((dom) => (
                              <Option key={dom.id} value={dom.id}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <p className="search-label">Ou</p>
                          <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Search OU"
                            optionFilterProp="children"
                            onChange={(e, val) => handleApplyFilter("adOu", val?.children)}
                            value={getCurrentOption("adOu")}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {ouData?.map((ou) => (
                              <Option key={ou.AdId} value={ou.Id}>
                                {ou.OuName}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                      </>
                    )}
                    <Col span={4}>
                      <p className="search-label">Endpoint</p>

                      <InfiniteScrollDropdown
                        fetchData={() => fetchInstance(search)}
                        loading={loading}
                        options={options}
                        placeholder="Search Endpoint "
                        hasMore={hasMore}
                        onSelect={(e) => handleApplyFilter("destinationEndpoint", e)}
                        value={getCurrentOption("destinationEndpoint")}
                        onSearch={(value) => {
                          const inputValue = value;
                          setSearch(inputValue);
                          setPage(1);
                          setOptions([]);
                          setHasMore(true);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row style={{ marginBottom: "2rem" }}>
                <Col>
                  {appliedFilters.length > 0 && (
                    <div className="filter-tags">
                      <h4
                        style={{
                          marginTop: "0rem",
                          marginBottom: "0.5rem",
                          fontWeight: "400",
                        }}
                      >
                        Applied Filters:
                      </h4>

                      {appliedFilters.map(({ filterParameter, filterValue }) => (
                        <>
                          <Tag
                            key={`${filterParameter}:${filterValue}`}
                            closable={filterParameter === "logType" ? false : true}
                            onClose={() => {
                              if (filterParameter !== "logType") {
                                handleRemoveFilter(filterParameter, filterValue);
                              }
                            }}
                          >
                            {`${filterParameter}: ${filterValue}`}
                          </Tag>
                        </>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DataGrid
                    rows={requestData}
                    columns={columns}
                    rowCount={totalCount}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    isRowExpandable={isRowExpandable}
                    getRowId={(row) => row?.id?.toString()}
                    expandedRows={expandedRows}
                    onRowExpand={handleRowExpand}
                    paginationMode="server"
                    page={currentPageModal.current}
                    pageSize={currentPageModal.pageSize}
                    pageSizeOptions={[10]}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 100, page: 0 },
                      },
                    }}
                    paginationModel={currentPageModal}
                    onPaginationModelChange={(params) => {
                      fetchRequest(params.page + 1);
                    }}
                    filterMode="server"
                    onFilterModelChange={(e) =>
                      handleApplyFilter(e.items[0].field, e.items[0].value)
                    }
                    disableRowSelectionOnClick
                    style={{ border: "none", width: "100%", overflow: "auto" }} // Set a fixed height for the grid
                    rowThreshold={0}
                    pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                    sx={{
                      "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                      },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                      noResultsOverlay: CustomNoRowsOverlay,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      {isModalVisible && (
        <ApproveRequestV1
          selectedRow={selectedRow}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
          notification={isNotification}
          endpointGroups={endpointGroups}
          epmGroups={endpointGroups}
          adUser={user}
          domainData={domainData}
          epm={endpointData}
          requestData={requestData}
          fetchRequest={fetchRequest}
        />
      )}
    </>
  );
};

export default Request;
