export const userRoles = {
  ADMIN: {
    canAccess: [
      "/",
      "/onboard/config",
      "/pam/endpointsUsers",
      "/pam/endpointuserDetails",
      "/pam/endpointsUsers/checkoutUser",
      "pam/endpointsUsers/reset-credentials",
      "/pam/endpointGroups",
      "/pam/endpointGroups/endpointGroupDetails",
      "/pam/endpoint",
      "/pam/endpoint/endpointDetails",
      "/pam/endpoint/addEndpoint/windows",
      "/pam/endpoint/addEndpoint/linux",
      "/pam/endpoint/passwordPolicies",
      "/pam/endpoint/agentLogs",
      "/pam/jumpServer",
      "/pam/jumpServer/add",
      "/pam/jumpServer-connections",
      "/pam/jumpServer-connections/createConnection",
      "/pam/jumpServer-sessionRecordings",
      "/pam/credentialRotation",
      "/pam/credentialRotation/addPassword",
      "/pam/credentialRotation/addSshKeys",
      "/pam/policy-request",
      "/pam/policy",
      "/pam/policy-logs",
      "/pam/policy/createEndpointAccessPolicy",
      "/pam/secrets",
      "/pam/secrets/add",
      "/workloadIdentity",
      "/workloadIdentity/addWorkloadIdentity",
      "/serviceCredentials",
      "/serviceAccount/addServiceAccount",
      "/serviceAccount",

      "/directory/users",
      "/directory/groups",
      "/directory/device",
      "/directory/activeDirectory",
      "/directory/did-dashboard",
      "/directory/did",
      "/directory/did/addHolderDID",
      "/directory/did/addIssuerDID",
      "/directory/did-walletUser",
      "/directory/did-verifiableCredentials",
      "/directory/did-transactionLogs",
      "/directory/did-walletUser/view-credentials/:id",

      "/windows/endpoints",
      "/windows/endpointPolicies",
      "/windows/createWindowPolicy",
      "/windows/requestLogs",
      "/windows/auditLogs",
      "/windows/applicationRequest",

      "/onboard",
      "/onboard/module",
      "/onboard/setup/serviceAccount",
      "/onboard/setup/mfa",
      "/onboard/setup/localAccount",
      "/onboard/setup/tenantConfig",
      "/splunk",
      "/tenantConfig",
      "/dit/endpointRules",
      "/dit/createPolicy",

      // UI Prototype
      "/pam/policy/v2",
      "/pam/policy/v2/createPolicy",

      // Radius
      "/pam/radius",
      "/pam/radius/onboard",
      "/pam/radius/onboard/microsoftNPS",
    ],
  },

  ENDUSER: {
    canAccess: [
      "/endUser/endpoints",
      "/endUser/connections",
      "/endUser/assignedVcs",
      "/endUser/request",
      "/endUser/policies",
    ],
  },
  SUPERADMIN: {
    canAccess: [
      "/org/dashboard",
      "/org/dashboard/details",
      "/setting",
      "/setting/okta",
      "/tenant",
      "/tenant/add",
      "/onboard/module",
      "/onboard",
      "/onboard/config",
      "/onboard/setup/azureAdUser",
      "/onboard/setup/mfa",
      "/onboard/setup/localAccount",
    ],
  },
};

export const authorize = (role, page, id) => {
  if (id) {
    const access = userRoles[role].canAccess.map((path) => {
      path.replace("dynamicId", id);
    });
    if (access.includes(page)) {
      return true;
    }
    return false;
  } else {
    if (userRoles[role].canAccess.includes(page)) {
      return true;
    } else {
      return false;
    }
  }
};
