import { Button, Modal, Row, Col, Input, message, Spin, Form, Select } from "antd";
import React, { useState, useEffect } from "react";
import {
  AUTH_TOKEN,
  REACT_APP_PAM_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_TENANTS_URL,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";

const AssignEndpointToWallet = ({
  isAssignWallet,
  setIsAssignWallet,
  selectedEndpoints,
  fetchInstances,
}) => {
  const [epmUserData, setEpmUserData] = useState([]);
  const [issuer, setIssuer] = useState([]);
  const [walletData, setwalletData] = useState([]);
  const [defaultValue, setDefaultValue] = useState({});
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [IsBtnLoading, setIsBtnLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [filterBy, setfilterBy] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [endpointData, setEndpointData] = useState([]);

  const [publicIp, setPublicIp] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const PAGESIZE = 10;
  const [currentPageModel, setCurrentPageModel] = useState({
    pageSize: PAGESIZE,
    page: 0,
  });

  const [paginationParams, setPaginationParams] = useState({
    page: 0,
    pageSize: 5,
  });

  const [notification, setNotification] = message.useMessage();

  const [totalCount, setTotalCount] = useState(1);

  useEffect(() => {
    fetchEPMUsers();
    fetchInstance();
    fetchWallets();
    fetchIssuerList();
    fetchDefaultIssuer();
  }, []);

  const fetchInstance = () => {
    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageId: currentPage + 1,
      pageSize: currentPageModel.pageSize,
      search,
      filter: {
        filterBy: filterBy,
        value: filteredValue,
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, pageDetails, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setIsLoading(false);
        setEndpointData(
          res?.data?.instances?.map((data, i) => ({
            id: i + 1,
            ...data,
          }))
        );
        setTotalCount(res?.data?.totalCount);
        setCurrentPageModel({
          pageSize: 10,
          page: currentPage,
        });
        setPublicIp(
          res?.data?.instances?.map((data) => ({
            publicip: data.publicIp,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch endpoints",
          });
        }
      });
  };

  const fetchDefaultIssuer = () => {
    const payloadIssuer = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    setIsLoading(true);
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getDefaultIssuer`, payloadIssuer, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then(({ data }) => {
        setIsLoading(false);
        setDefaultValue({ issuername: data.issuername });
        form.setFieldsValue({
          issuerId: data.data,
          issuername: data.issuername,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIssuer(
          res?.data?.map((data) => ({
            id: data.IssuerId,
            text: data.Name,
          })) || []
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch IssuerList!",
          });
        }
      });
  };

  const fetchEPMUsers = () => {
    setIsLoading(true);

    let requestData = {
      domainId: DOMAIN_ID(),
      pageId: search || filterBy ? 1 : currentPage + 1,
      pageSize: currentPageModel.pageSize,
      instanceIds: [selectedEndpoints.instanceId],
      filter: {
        filterBy: filterBy,
        Value: "",
      },
      search,
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, requestData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEpmUserData(res?.data?.epmUsers);
      })
      .catch((err) => {
        setIsLoading(false);
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch EPM users",
          });
        }
      });
  };

  const fetchWallets = async (e) => {
    setIsLoading(true);

    let pageDetails = {
      domainId: DOMAIN_ID(),
      pageNumber: paginationParams.page + 1,
      pageSize: paginationParams.pageSize,
      filter: search,
      orgId: ORG_ID(),
    };
    try {
      let res = await axios.post(
        `${REACT_APP_API_DID_URL}/walletService/walletUserList`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );

      setTotalCount(res?.data?.count);
      console.log(res.data);
      setwalletData(
        res?.data?.users?.map((user) => ({
          id: user.userId,
          email: user.emailAddress ? user.emailAddress : "-",
          name: user.username ? user.username : "-",
          walletUrl: user.walletUrl,
          noOfcredential: user.noOfcredential,
          walletId: user.walletId,
        }))
      );

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);

      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
        notification.open({
          type: "error",
          content: "Unable to fetch wallets",
        });
      }
    }
  };

  const handleWalletassign = (values) => {
    let walletData = {
      domainId: DOMAIN_ID(),
      issuerId: values.issuerId,
      epmUsers: [
        {
          epmUserId: Number(values.epmuser),
          instanceId: Number(selectedEndpoints.instanceId),
        },
      ],
      users: values.wallet,
      orgId: ORG_ID(),
    };

    console.log(walletData);

    axios
      .post(`${REACT_APP_PAM_API}/checkouts/assignEpmUserToWallet`, walletData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: "User Assigned to wallet!",
        });
        setTimeout(() => {
          setIsAssignWallet(false);
          fetchInstances();
        }, 2000);
      })
      .catch((err) => {
        if (err.response.data.message == "Invalid credentials") {
          logOutUser();
          notification.open({
            type: "error",
            content: "Credentials are invalid!",
          });
        } else if (err.response.status == 401) {
          logOutUser();
          notification.open({
            type: "error",
            content: "Error Assigning user to wallet!",
          });
        }
        notification.open({
          type: "error",
          content: "Failed to assign User to wallet!",
        });
      });
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setIsBtnLoading(true);
      setIsLoading(true);
      handleWalletassign(values);
      setIsLoading(false);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const handleCancel = () => {
    setIsAssignWallet(false);
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontSize: 18 }}>
            Assign Wallet to{" "}
            <span style={{ color: "#1677FF" }}>{` ${selectedEndpoints?.hostName}`}</span>
          </span>
        }
        open={isAssignWallet}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} loading={IsBtnLoading}>
            Assign
          </Button>,
        ]}
        width={950}
      >
        {setNotification}

        <br />
        <Spin spinning={isLoading}>
          <Row>
            <Col span={24}>
              <Form
                form={form}
                layout="vertical"
                initialValues={{ issuerId: defaultValue.issuerId }}
                style={{ minHeight: "200px" }}
                onFinish={handleOk}
              >
                <Row gutter={16} style={{ marginBottom: "16px" }}>
                  <Col span={12}>
                    <Form.Item
                      //name="endpoint"
                      label="Host Name"
                      rules={[
                        {
                          required: true,
                          message: "Host Name !",
                        },
                      ]}
                    >
                      <Input disabled placeholder="Host Name" value={selectedEndpoints.hostName} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      //name="endpoint"
                      label="Public IP"
                      rules={[
                        {
                          required: true,
                          message: "Public IP !",
                        },
                      ]}
                    >
                      <Input disabled placeholder="Public IP" value={selectedEndpoints.publicIp} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: "16px" }}>
                  <Col span={12}>
                    <Form.Item
                      name="epmuser"
                      label="Endpoint Users"
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint User!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Endpoint User"
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={epmUserData.map((epmUserData) => ({
                          value: epmUserData.userId,
                          label: epmUserData.userName,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="wallet"
                      label="Wallet Users"
                      rules={[
                        {
                          required: true,
                          message: "Select Wallet Users!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select Wallet Users"
                        showSearch
                        filterOption={(input, option) =>
                          option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={walletData.map((walletData) => ({
                          value: walletData.id,
                          label: walletData.email,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginBottom: "16px" }}>
                  <Col span={12}>
                    <Form.Item
                      name="issuerId"
                      label="Issuer"
                      rules={[
                        {
                          required: true,
                          message: "Select Issuer!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Issuer"
                        showSearch
                        optionFilterProp="label"
                        options={issuer.map((issuer) => ({
                          value: issuer.id,
                          label: issuer.text,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default AssignEndpointToWallet;
