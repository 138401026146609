import { Button, Col, Form, Row, Select, message, Tooltip, Menu } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API, REACT_APP_PAM_API } from "../../constants";
import {
  ApartmentOutlined,
  FieldTimeOutlined,
  FormOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  LaptopOutlined,
  PropertySafetyOutlined,
  RetweetOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";

const { Option } = Select;

const SIDEBAR_MENU = [
  { key: "1", icon: <FormOutlined />, label: "Basic policy infromation" },
  { key: "2", icon: <FieldTimeOutlined />, label: "Timebound access" },
  { key: "3", icon: <PropertySafetyOutlined />, label: "Require Service tickets /justification" },
  { key: "4", icon: <FieldTimeOutlined />, label: "Just in time" },
  { key: "5", icon: <RetweetOutlined />, label: "Access workflow" },
  { key: "6", icon: <GlobalOutlined />, label: "Offline access" },
  { key: "7", icon: <UserOutlined />, label: "Bypass local root" },
  { key: "8", icon: <UserOutlined />, label: "Networks" },
  { key: "9", icon: <LaptopOutlined />, label: "Devices" },
  { key: "10", icon: <PropertySafetyOutlined />, label: "Identity Risk" },
  { key: "11", icon: <UserOutlined />, label: "User Behaviorial analytics" },
  { key: "12", icon: <TeamOutlined />, label: "Groups" },
  { key: "13", icon: <ApartmentOutlined />, label: "Endpoints" },
];

const POLICY_TYPES = [
  { value: "endpoints", label: "Endpoint user policies" },
  { value: "AD", label: "Group policy for a given user or groups" },
  // { value: "serviceAccountPolicy", label: "Group policy for service account" },
  // { value: "databasePolicy", label: "Database policy" },
  { value: "radius", label: "Network device access policies" },
];

const NETWORK_DEVICES = [
  {
    instanceId: 101,
    instanceName: "radius-endpoint-1",
    osName: "radius",
    publicIP: "10.128.0.59",
    privateIP: "192.168.1.100",
    hostName: "radius-server-1",
    status: "active",
    lastActive: "2024-11-26 08:00:00",
    machineType: "radius",
    autoPopulate: true,
    uuid: "550e8400-e29b-41d4-a716-446655440000",
  },
  {
    instanceId: 102,
    instanceName: "radius-endpoint-2",
    osName: "radius",
    publicIP: "10.128.0.60",
    privateIP: "192.168.1.101",
    hostName: "radius-server-2",
    status: "active",
    lastActive: "2024-11-26 07:55:00",
    machineType: "radius",
    autoPopulate: true,
    uuid: "550e8400-e29b-41d4-a716-446655440001",
  },
];

export default function CreatePolicyBasics() {
  const [form] = Form.useForm();
  const [notification, setNotificationHolder] = message.useMessage();
  const navigate = useNavigate();

  const [policyName, setPolicyName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [policyType, setPolicyType] = useState("");
  const [endpointsData, setEndpointsData] = useState([]);
  const [endpointGroupsData, setEndpointGroupsData] = useState([]);
  const [adGroupsData, setAdGroupsData] = useState([]);
  const [endpointUsersData, setEndpointUsersData] = useState([]);
  const [domainData, setDomainData] = useState([]);
  const [OUData, setOUData] = useState([]);
  const [selectedDomainOU, setSelectedDomainOU] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [selectedEndpointGroups, setSelectedEndpointGroups] = useState([]);
  const [selectedEndpoints, setSelectedEndpoints] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);
  const [adUsersData, setAdUsersData] = useState([]);
  const [infraSelectedADGroups, setInfraSelectedADGroups] = useState([]);
  const [networkDevices, setNetworkDevices] = useState([]);

  useEffect(() => {
    generateDynamicPolicyName("", 36);
    fetchDomains();
    fetchDomainOU();
    fetchWalletUsers();
  }, []);

  useEffect(() => {
    if (policyType === "endpoints") {
      fetchEndpointGroups();
    } else if (policyType === "AD" || policyType === "serviceAccountPolicy") {
      fetchEndpointGroups();
    } else if (policyType === "radius") {
      fetchNetworkDevices();
    }
  }, [policyType]);

  useEffect(() => {
    if (selectedDomain !== null) {
      fetchADGroups();
    }
  }, [selectedDomain]);

  useEffect(() => {
    if (selectedEndpointGroups.length > 0) {
      fetchEndpoints();
    }
  }, [selectedEndpointGroups]);

  useEffect(() => {
    if (selectedEndpoints != []) {
      fetchEndpointUsers();
    }
  }, [selectedEndpoints]);

  useEffect(() => {
    if (infraSelectedADGroups != []) {
      fetchADUsers();
    }
  }, [infraSelectedADGroups]);

  // Function to generate a dynamic name based on a template
  const generateDynamicPolicyName = (prefix, length) => {
    const characters = "0123456789";
    let result = prefix; // Fixed part

    const sections = [7, 8];
    for (let sectionLength of sections) {
      for (let i = 0; i < sectionLength; i++) {
        const randomCharIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomCharIndex);
      }
      if (sectionLength !== 8) {
        result += "-";
      }
    }
    return setPolicyName(result);
  };

  const handleFinish = (values) => {
    setIsSubmitting(true);
    let groups = {};
    let adPolicyPayload = {};
    const iamGroups = values.ADGroups?.map((group) => group.split("|")[1]?.trim()) || [];

    if (values.policyType === "endpoints" || values.policyType === "AD") {
      // endpoints and endpoint groups mapping
      groups =
        values?.endpointGroups?.reduce((acc, group) => {
          const [, groupName] = group.split("|");
          acc[groupName.trim()] = [];
          return acc;
        }, {}) || {};

      const trimmedEndpoints =
        values.endpoints?.map((endpoint) => endpoint.split("|")[1]?.trim()) || [];

      const filteredEndpoints = endpointsData?.filter((endpoint) =>
        trimmedEndpoints.includes(endpoint.hostname)
      );

      filteredEndpoints?.forEach((endpoint) => {
        const endpointGroupName = endpoint.groupname?.trim();
        if (groups[endpointGroupName]) {
          groups[endpointGroupName].push(endpoint.hostname);
        }
      });
    }

    if (values.policyType === "AD") {
      const infraADGroups = values.InfaADGroups?.map((group) => group.split("|")[1]?.trim()) || [];
      const infraOUData = values.infraOU?.map((ou) => ou.split(",")[0].split("=")[1]) || [];

      adPolicyPayload = {
        policyName: policyName,
        policyType: values.policyType,
        ad: {
          ous: infraOUData,
          groups: infraADGroups,
          users: values?.infraADUsers,
        },
        endpoints: {
          groups: groups ? groups : {},
        },
        iam: {
          iam_users: values.iamUsers,
          iam_groups: iamGroups,
        },
        permissions: {
          allowed: true,
        },
      };
    }

    let endpointPolicyPayload = {
      policyName: policyName,
      policyType: values.policyType,
      endpoints: {
        groups: groups ? groups : {},
        users: values.endpointUsers,
      },
      iam: {
        iam_users: values.iamUsers,
        iam_groups: iamGroups,
      },
      permissions: {
        allowed: true,
      },
    };

    let radiusPolicyPayload = {
      policyName: policyName,
      policyType: values.policyType,
      networks: {
        endpoints: values.networkDevices,
      },
      iam: {
        iam_users: values.iamUsers,
        iam_groups: iamGroups,
      },
      permissions: {
        allowed: true,
      },
    };

    const payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      policyName: policyName,
      policyType: values.policyType,
      policyJson:
        values.policyType === "endpoints"
          ? endpointPolicyPayload
          : values.policyType === "radius"
          ? radiusPolicyPayload
          : values.policyType === "AD"
          ? adPolicyPayload
          : {
              iam: {
                iam_users: values.iamUsers,
                iam_groups: iamGroups,
              },
              permissions: {
                allowed: true,
              },
            },
    };

    axios
      .post(`${REACT_APP_API}/api/v1/policyService/CreateJSONPolicy`, payload)
      .then(() => {
        message.success("Policy created successfully");
        setIsSubmitting(false);
        navigate("/pam/policy/v2");
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Failed to create policy:", error);
        message.error("Failed to create policy");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const fetchDomains = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        filter: "",
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllDomains`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.domains?.map((ad, i) => ({
        id: i + 1,
        ...ad,
      }));

      setDomainData(tempData ? tempData : []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchDomainOU = async () => {
    try {
      let payload = {
        org_Id: ORG_ID(),
        tenant_Id: DOMAIN_ID(),
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/listOU`, payload, {
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      setOUData(res?.data?.data || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchADGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        pageNo: 1,
        pageSize: 100,
        filter: "",
        adId: selectedDomain,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllGroupsByDomain`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setAdGroupsData(res?.data?.groups || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchWalletUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageNumber: 1,
        pageSize: 100,
        search: "",
        filter: "",
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/walletService/walletUserList`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.emailAddress,
          label: user.emailAddress,
        };
      });
      setWalletUsers(tempData || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  // API Calls for Endpoint Policy
  const fetchEndpointGroups = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetUniqueGroups`,
        payload
      );
      const tempData = res?.data?.groups?.map((group, index) => {
        return {
          id: index + 1,
          value: group.group_id,
          label: group.group_name,
        };
      });
      setEndpointGroupsData(tempData || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpoints = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        group_ids: selectedEndpointGroups,
      };
      const res = await axios.post(
        `${REACT_APP_API}/api/v1/policyService/GetMachinesByGroupID`,
        payload
      );

      setEndpointsData(res?.data?.machines || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointUsers = async () => {
    try {
      let payload = {
        org_id: ORG_ID(),
        tenant_id: DOMAIN_ID(),
        machine_ids: selectedEndpoints,
      };
      const res = await axios.post(`${REACT_APP_API}/api/v1/policyService/GetUsers`, payload);
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.epm_user_name,
          label: user.epm_user_name,
        };
      });
      setEndpointUsersData(tempData || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  // API Calls for AD Policy
  const fetchADUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        tenantId: DOMAIN_ID(),
        groupId: infraSelectedADGroups,
      };
      const res = await axios.post(`${REACT_APP_API}/ad/GetAllUsersByGroup`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.users?.map((user, index) => {
        return {
          id: index + 1,
          value: user.userName,
          label: user.userName,
        };
      });
      setAdUsersData(tempData || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  // API Calls for Radius Policy
  const fetchNetworkDevices = async () => {
    let payload = {
      OrgID: 105,
      DomainID: 1,
      Search: "",
      Filter: {
        FilterBy: "",
        Value: "",
      },
      PageSize: 100,
      PageID: 1,
      MachineType: "radius",
    };

    try {
      const res = await axios.post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });

      const tempData = res?.data?.instances?.map((instance, index) => {
        return {
          id: index + 1,
          value: instance?.publicIp,
          label: instance?.hostName,
        };
      });
      setNetworkDevices(tempData || []);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  return (
    <Row className="content-container">
      {setNotificationHolder}
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Create Policy</h2>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={20}>
          <Col span={16}>
            <Row gutter={16}>
              <Col span={24}>
                <Form form={form} layout="vertical" onFinish={handleFinish}>
                  <h3>
                    What kind of policy would you like to create?{"  "}
                    <Tooltip title="Select the type of policy you want to create. Each policy type is tailored to manage specific access controls or permissions">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </h3>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Policy Type"
                        name="policyType"
                        rules={[{ required: true, message: "Please select a policy type" }]}
                      >
                        <Select
                          placeholder="Select policy type"
                          onSelect={(value) => setPolicyType(value)}
                          allowClear
                        >
                          {POLICY_TYPES?.map((policy, index) => (
                            <Option key={index} value={policy.value}>
                              {policy.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <h3>
                    Who will get access (from your IAM)?{"  "}
                    <Tooltip title="Select the users, groups, or entities who will be granted access through this policy. Ensure the selections align with the intended scope of the policy">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </h3>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Select Domain"
                        name="domain"
                        rules={[{ required: true, message: "Please select a domain" }]}
                      >
                        <Select
                          placeholder="Select domain"
                          onChange={(e, val) => {
                            const domainId = parseInt(e.split("|")[0], 10);
                            const selectedDomain = domainData.find(
                              (domain) => domain.id === domainId
                            );
                            if (selectedDomain) {
                              setSelectedDomain(selectedDomain.id);
                            }
                            const selectedOu = OUData.filter(
                              (ou) => ou.ad_id === selectedDomain.id
                            );
                            if (selectedOu) {
                              setSelectedDomainOU(selectedOu);
                            }
                          }}
                          allowClear
                        >
                          {domainData?.map((dom) => (
                            <Option key={dom.id} value={`${dom.id}|${dom.directoryName}`}>
                              {dom.directoryName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="OU"
                        name="OU"
                        rules={[{ required: true, message: "Please select OU" }]}
                      >
                        <Select mode="multiple" placeholder="Select OU" allowClear>
                          {selectedDomainOU?.map((ou) => (
                            <Option key={ou?.id} value={ou?.ou_name}>
                              {ou?.ou_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={
                          <span>
                            Select AD Groups &nbsp;
                            <Tooltip title="Select one or more AD groups.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="ADGroups"
                        rules={[{ required: true, message: "Please select a group" }]}
                      >
                        <Select mode="multiple" placeholder="Select group">
                          {adGroupsData?.map((group, index) => (
                            <Option key={index} value={`${group.id}|${group.groupName}`}>
                              {group.groupName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={
                          <span>
                            Select IAM Users &nbsp;
                            <Tooltip title="Select one or users.">
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                        name="iamUsers"
                      >
                        <Select mode="multiple" placeholder="Select users">
                          {walletUsers?.map((user, index) => (
                            <Option key={index} value={user?.value}>
                              {user?.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {policyType !== "" && (
                    <h3>
                      What Infrastructure will they get access to?{"  "}
                      <Tooltip title="Specify the resources or entities this policy will apply to. This could include users, endpoints, groups, or other relevant infrastructure components, depending on the policy type.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </h3>
                  )}

                  {/* Endpoint policy */}
                  {policyType === "endpoints" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Endpoint Groups &nbsp;
                              <Tooltip title="Select one or more endpoint groups.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="endpointGroups"
                          rules={[{ required: true, message: "Please select an endpoint group" }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select Endpoint group"
                            onChange={(value) => {
                              const selectedGroupId = value?.map((item) => {
                                return parseInt(item.split("|")[0]);
                              });
                              setSelectedEndpointGroups(selectedGroupId);
                            }}
                          >
                            {endpointGroupsData?.map((group, index) => (
                              <Option key={index} value={`${group.value}|${group.label}`}>
                                {group.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Endpoints &nbsp;
                              <Tooltip title="Select one or more endpoints.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="endpoints"
                          rules={[{ required: true, message: "Please select endpoints" }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select endpoint"
                            onChange={(value) => {
                              const selectedEndpointIds = value?.map((item) => {
                                return parseInt(item.split("|")[0]);
                              });
                              setSelectedEndpoints(selectedEndpointIds);
                            }}
                          >
                            {endpointsData?.map((endpoint, index) => (
                              <Option
                                key={index}
                                value={`${endpoint.machine_id}|${endpoint.hostname}`}
                              >
                                {endpoint.hostname}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Endpoint Users &nbsp;
                              <Tooltip title="Select one or more endpoint users.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="endpointUsers"
                          rules={[{ required: true, message: "Please select endpoint users" }]}
                        >
                          <Select mode="multiple" placeholder="Select endpoint users">
                            {endpointUsersData?.map((user, index) => (
                              <Option key={index} value={user.value}>
                                {user.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* AD Policy */}
                  {policyType === "AD" && (
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Select Domain"
                          name="infraDomain"
                          rules={[{ required: true, message: "Please select a domain" }]}
                        >
                          <Select
                            placeholder="Select domain"
                            onChange={(e, val) => {
                              const domainId = parseInt(e.split("|")[0], 10);
                              const selectedDomain = domainData.find(
                                (domain) => domain.id === domainId
                              );
                              if (selectedDomain) {
                                setSelectedDomain(selectedDomain.id);
                              }
                              const selectedOu = OUData.filter(
                                (ou) => ou.ad_id === selectedDomain.id
                              );
                              if (selectedOu) {
                                setSelectedDomainOU(selectedOu);
                              }
                            }}
                            allowClear
                          >
                            {domainData?.map((dom) => (
                              <Option key={dom.id} value={`${dom.id}|${dom.directoryName}`}>
                                {dom.directoryName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="OU"
                          name="infraOU"
                          rules={[{ required: true, message: "Please select OU" }]}
                        >
                          <Select mode="multiple" placeholder="Select OU" allowClear>
                            {selectedDomainOU?.map((ou) => (
                              <Option key={ou?.id} value={ou?.ou_name}>
                                {ou?.ou_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select AD Groups &nbsp;
                              <Tooltip title="Select one or more AD groups.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="InfaADGroups"
                          rules={[{ required: true, message: "Please select a group" }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select group"
                            onChange={(selectedIds) => {
                              const selectedGroupIds = selectedIds?.map((item) =>
                                parseInt(item.split("|")[0], 10)
                              );
                              setInfraSelectedADGroups(selectedGroupIds);
                            }}
                          >
                            {adGroupsData?.map((group, index) => (
                              <Option key={index} value={`${group.id}|${group.groupName}`}>
                                {group.groupName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select AD Users &nbsp;
                              <Tooltip title="Select one or users.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="infraADUsers"
                        >
                          <Select mode="multiple" placeholder="Select users">
                            {adUsersData?.map((user, index) => (
                              <Option key={index} value={user?.value}>
                                {user?.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Endpoint Groups &nbsp;
                              <Tooltip title="Select one or more endpoint groups.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="endpointGroups"
                          rules={[{ required: true, message: "Please select an endpoint group" }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select Endpoint group"
                            onChange={(value) => {
                              const selectedGroupId = value?.map((item) => {
                                return parseInt(item.split("|")[0]);
                              });
                              setSelectedEndpointGroups(selectedGroupId);
                            }}
                          >
                            {endpointGroupsData?.map((group, index) => (
                              <Option key={index} value={`${group.value}|${group.label}`}>
                                {group.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Endpoints &nbsp;
                              <Tooltip title="Select one or more endpoints.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="endpoints"
                          rules={[{ required: true, message: "Please select endpoints" }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select endpoint"
                            onChange={(value) => {
                              const selectedEndpointIds = value?.map((item) => {
                                return parseInt(item.split("|")[0]);
                              });
                              setSelectedEndpoints(selectedEndpointIds);
                            }}
                          >
                            {endpointsData?.map((endpoint, index) => (
                              <Option
                                key={index}
                                value={`${endpoint.machine_id}|${endpoint.hostname}`}
                              >
                                {endpoint.hostname}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  {/* Radius policy */}
                  {policyType === "radius" && (
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <span>
                              Select Network Devices &nbsp;
                              <Tooltip title="Select one or more network devices.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </span>
                          }
                          name="networkDevices"
                          rules={[{ required: true, message: "Please select devices" }]}
                        >
                          <Select mode="multiple" placeholder="Select network devices">
                            {networkDevices.map((device, index) => (
                              <Option key={index} value={device?.value}>
                                {`${device?.label} - (${device?.value})`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row justify="end" gutter={16}>
                    <Col>
                      <Link to={"/pam/policy/v2"}>
                        <Button>Cancel</Button>
                      </Link>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isSubmitting}>
                          Create Policy
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Menu selectedKeys={["1"]}>
              {SIDEBAR_MENU?.map((menu) => (
                <Menu.Item key={menu?.key} icon={menu?.icon}>
                  {menu?.label}
                </Menu.Item>
              ))}
            </Menu>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
