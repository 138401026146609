import {
  AUTH_TOKEN,
  REACT_APP_API,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  DOMAIN_ID,
  ORG_ID,
} from "../constants";
import axios from "axios";
import moment from "moment-timezone";
import { logOutUser } from "../common";

export let HEADERS = {
  headers: {
    withCredentials: true,
    "X-Authorization": AUTH_TOKEN(),
  },
};

export const fetchEPMUsers = async function ({
  pageId = 1,
  pageSize = 10,
  search = "",
  filterBy = "",
  filterValue = "",
}) {
  let epmUsers = [];
  let totalRecords = 0;

  let pageDetails = {
    search,
    pageId,
    pageSize,
    domainId: DOMAIN_ID(),
    token: AUTH_TOKEN(),
    instanceIds: [],
    filter: { filterBy, value: filterValue },
    orgId: ORG_ID(),
  };

  try {
    let res = await axios.post(`${REACT_APP_PAM_API}/endpointUsers/listAllEpmUsers`, pageDetails, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    });

    if (res.data.epmUsers) {
      totalRecords = res?.data?.totalCount;

      epmUsers = res.data.epmUsers.map((user, i) => {
        return {
          index: i,
          id: `${user.userId} ${user.credentialId}`,
          user: user.userName,
          epmUserId: user.userId,
          type: user.credType,
          usertype: user.userType,
          credentials: user.countOfCredentials,
          instance: user.instances[0],
          credId: user.credentialId,
          wallets: user.wallets == null ? [] : user.wallets.map((i) => i.walletId),
        };
      });
    }
  } catch (err) {
    if (
      err?.response?.status === 401 ||
      err.response.data?.Message === "Invalid credentials" ||
      err.response.data?.Message === "Unauthorized"
    ) {
      logOutUser();
    }
    console.log({ err });
  }

  return { epmUsers, totalRecords };
};

export const doAssign = async function (epmUserId, instanceId, issuerId, assignees) {
  let walletData = {
    domainId: DOMAIN_ID(),
    issuerId: issuerId,
    epmUsers: [{ epmUserId, instanceId }],
    users: assignees,
  };

  await axios.post(`${REACT_APP_API_PAM_URL}/checkouts/assignEpmUserToWallet`, walletData, {
    headers: {
      "X-Authorization": AUTH_TOKEN(),
      withCredentials: true,
    },
  });
};

export const fetchDirectoryUsers = async function () {
  let data;
  let pageDetails = {
    domainId: DOMAIN_ID(),
    pageId: 1,
    pageSize: 1000,
    search: "",
    filter: {
      filterBy: "",
      value: "",
    },
    orgId: ORG_ID(),
  };

  let res = await axios.post(`${REACT_APP_API_PAM_URL}/users/listAll`, pageDetails, {
    headers: {
      "X-Authorization": AUTH_TOKEN(),
      withCredentials: true,
    },
  });

  if (res.data.users) {
    data = res.data.users.map((user, i) => ({
      index: i,
      id: user.id,
      firstName: user.firstName,
      phone: user.phoneNumber,
      lastName: user.lastName,
      email: user.emailAddress,
      status: user.status,
      apps: user.applications ? user.applications.map((app) => Number(app.id)) : [],
      otpMethod: user.otpMethod,
      metaData: user.metadata,
      groups: user.groups,
      dc: "abc",
      cn: "asif ali",
      ou: "penang",
      ou1: "penang",
      domain: "Authnull-India",
    }));
  } else {
    data = [];
  }

  return data;
};

export const fetchWallets = async () => {
  let wallets;

  let pageDetails = {
    domainId: DOMAIN_ID(),
    pageNumber: 1,
    pageSize: 15000,
    filter: "",
    orgId: ORG_ID(),
  };

  try {
    let res = await axios.post(
      `${REACT_APP_API}/api/v1/walletService/walletUserList`,
      pageDetails,
      {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      }
    );
    if (res.data.users) {
      wallets = res?.data?.users?.map((user) => {
        return {
          id: user.userId,
          email: user.emailAddress ? user.emailAddress : "-",
          name: user.username ? user.username : "-",
          walletUrl: user.walletUrl,
          noOfcredential: user.noOfcredential,
          userId: user.walletId,
        };
      });
    } else {
      wallets = [];
    }
  } catch (err) {
    if (
      err &&
      err.response &&
      err.response.data &&
      err.response.data.message === "Invalid credentials"
    ) {
      //        logOutUser();
    } else if (err && err.response && err.response.data && err.response.status === 401) {
      //        logOutUser();
    } else {
      //        isNotification = true;
      //        notification = {
      //          kind: "error",
      //          title: "Error:",
      //          subtitle: " Error Fetching wallets users.",
      //        };
    }
  }

  return wallets;
};

export const fetchEndpoints = async function ({ pageId = 1, pageSize = 1000, search = "" }) {
  let pageDetails = {
    domainId: DOMAIN_ID(),
    pageId,
    pageSize,
    search,
    filter: {
      filterBy: "",
      value: "",
    },
    token: AUTH_TOKEN(),
    orgId: ORG_ID(),
    MachineType: "endpoint",
  };
  const res = await axios
    .post(`${REACT_APP_API_PAM_URL}/instances/list`, pageDetails, {
      headers: {
        "X-Authorization": AUTH_TOKEN(),
        withCredentials: true,
      },
    })
    .catch((err) => {
      if (err.response.data.message === "Invalid credentials" || err?.response?.status === 401) {
        // logOutUser();
      }
    });

  return res && res.data?.instances ? res.data.instances : [];
};

export const fetchIssuerList = async function () {
  let requestData = {
    domainId: DOMAIN_ID(),
    orgId: ORG_ID(),
  };

  const res = await axios.post(`https://api.did.authnull.com/api/v1/did/issuerList`, requestData, {
    headers: {
      "X-Authorization": AUTH_TOKEN(),
      withCredentials: true,
    },
  });

  return (
    res?.data?.map((data) => ({
      id: data.IssuerId,
      text: data.Name,
    })) || []
  );
};

export const fetchEndpointGroups = async function ({
  pageId = 1,
  pageSize = 1000,
  filterBy = "",
  filterValue = "adGroups",
}) {
  let groups = [];
  let totalRecords = 0;

  let pageDetails = {
    domainId: DOMAIN_ID(),
    pageId,
    pageSize,
    Filter: {
      filterBy,
      value: filterValue,
    },
    instanceId: 0,
    orgId: ORG_ID(),
  };

  const res = await axios.post(`${REACT_APP_API_PAM_URL}/groups/listAll`, pageDetails, {
    headers: {
      "X-Authorization": AUTH_TOKEN(),
      withCredentials: true,
    },
  });

  if (res.data.groups) {
    totalRecords = res?.data?.totalCount;
    groups = res.data.groups.map((grp, i) => ({
      index: i,
      id: grp.id,
      text: grp.groupName,
      people: grp.users.length,
      application: grp.applications.length,
      selectedApps: grp.applications ? grp.applications.map((app) => app.id) : [],
      selectedUsers: grp.users ? grp.users.map((user) => user.id) : [],
      ...grp,
    }));
  } else {
    groups = [];
  }

  return { groups, totalRecords };
};

export const fetchVerifiableCredentials = async function ({
  epmUserId,
  pageId = 1,
  pageSize = 1000,
  search = "",
}) {
  let totalRecords = 0;
  let credentialData = [];

  let vcRequest = {
    epmUserId,
    pageNumber: pageId,
    pageSize,
    filter: search,
  };

  const res = await axios
    .post(`${REACT_APP_API_DID_URL}/credential/getCredentialByEpmUser`, vcRequest, {
      headers: {
        withCredentials: true,
        "X-Authorization": AUTH_TOKEN(),
      },
    })
    .catch((err) => {});

  totalRecords = res.data.count;

  if (res?.data?.data) {
    credentialData = res.data.data.map((cred) => ({
      id: cred.id,
      createDate:
        cred.created_at &&
        moment(cred.created_at).tz("America/Los_Angeles").format("DD-MM-YYYY HH:mm [PST]"),
      userName: cred.credential_name,
      issuer: cred.issuer_name,
      schema: cred.user_source,
      status: cred.status,
    }));
  } else {
    credentialData = [];
  }

  return { credentialData, totalRecords };
};
