import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Row, Col, message } from "antd";
import {
  AUTH_TOKEN,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
} from "../../../constants";
import axios from "axios";
import { logOutUser } from "../../../common";
import { fetchEndpoints } from "../../../common/functions";

const { TextArea } = Input;
const { Option } = Select;

const AddGroupModal = ({ isAddgroup, setIsAddgroup }) => {
  const [groupName, setGroupName] = useState("");
  const [baseDN, setBaseDN] = useState("");
  const [nameFormat, setNameFormat] = useState("");
  const [groupFormat, setGroupFormat] = useState("");
  const [metaData, setMetaData] = useState("");
  const [validMetaData, setValidMetaData] = useState(true);
  const [instanceData, setInstanceData] = useState([]);
  const [selectedInstances, setSelectedInstances] = useState([]);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = message.useMessage();
  const [formData, setFormData] = useState();

  useEffect(() => {
    const fetchEndpoints = () => {
      let payload = {
        domainId: DOMAIN_ID(),
        pageId: 1,
        pageSize: 100,
        search: "",
        filter: {
          filterBy: "",
          value: "",
        },
        token: AUTH_TOKEN(),
        orgId: ORG_ID(),
        MachineType: "endpoint",
      };
      axios
        .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        })
        .then((res) => {
          setInstanceData(
            res?.data?.instances.map((instance) => ({
              id: instance.instanceId,
              text: instance.hostName,
              publicIp: instance.publicIp,
            }))
          );
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.response.data.message === "Invalid credentials" ||
            err?.response?.status === 401
          ) {
            logOutUser();
            notification.open({
              type: "error",
              content: "Credentials are invalid",
            });
          } else {
            notification.open({
              type: "error",
              content: "Unable to fetch endpoints",
            });
          }
        });
    };
    fetchEndpoints();

    setFormData([
      { name: ["groupName"], value: groupName },
      { name: ["endpoints"], value: selectedInstances },
    ]);
  }, []);

  const fetchEndpointGroup = async () => {
    let DOMAIN_ID = localStorage.getItem("DomainId");

    let pageDetails = {
      domainId: DOMAIN_ID,
      pageId: 1,
      pageSize: 10,
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      filter: {
        filterBy: "",
        value: "",
      },
    };

    try {
      let res = await axios.post(
        `${REACT_APP_PAM_API}/instanceGroup/listEndpointGroup`,
        pageDetails,
        {
          headers: {
            "X-Authorization": AUTH_TOKEN(),
            withCredentials: true,
          },
        }
      );
      let Records = res?.data?.totalCount;
      if (res.data.groups) {
        let endpointGroupData = res?.data?.groups?.map((grp, i) => ({
          index: i,
          id: grp?.groupId,
          groupName: grp?.groupName,
          count: grp?.usersCount,
          // credential: grp.CredentialExpiry,
        }));
      } else {
      }
    } catch (err) {
      if (err.response.data.message == "Invalid credentials") {
        logOutUser();
      } else if (err.response.status == 401) {
        logOutUser();
      } else {
      }
    }
  };

  const handleSaveGroup = (e) => {
    let data = {
      domainId: DOMAIN_ID(),
      groupName: groupName,
      instanceIds: selectedInstances ?? [],
      ou: groupFormat ?? "",
      cn: nameFormat ?? "",
      dc: baseDN ?? "",
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };

    axios
      .post(`${REACT_APP_API_PAM_URL}/instanceGroup/addInstanceGroup`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(() => {
        notification.open({
          type: "success",
          content: `Adding Group Successful`,
        });
        fetchEndpointGroup();
      })
      .catch((err) => {
        if (err.response.data.message == "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else if (err.response.status == 401) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to Add Group",
          });
        }
      });
  };

  const handleOk = () => {
    let pageId = 1;
    if (groupName.length) {
      handleSaveGroup();
    }
    setTimeout(() => {
      setIsAddgroup(false);
    }, 3000);
  };
  const handleCancel = () => {
    setIsAddgroup(false);
  };
  console.log(selectedInstances);

  return (
    <Modal
      open={isAddgroup}
      title={`Add Group`}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[null]}
      width={800}
    >
      {setNotification}
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOk}
            fields={formData}
            onFieldsChange={(_, allFields) => {
              setFormData(allFields);
            }}
          >
            <Row className="search-box-container">
              <Col span={24}>
                <Form.Item
                  name="groupName"
                  label="Group Name"
                  rules={[
                    {
                      required: true,
                      message: "Group name required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter group name"
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: "2rem", gap: "1.5rem" }} className="search-box-container">
              <Col span={24}>
                <Form.Item
                  name="endpoints"
                  label="Endpoints"
                  rules={[
                    {
                      required: true,
                      message: "Select Endpoints",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    mode="multiple"
                    placeholder="Search Endpoints"
                    allowClear
                    onChange={(value) => setSelectedInstances(value)}
                    options={instanceData.map((instanceData) => ({
                      value: instanceData.id,
                      label: instanceData.text,
                    }))}
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <p className="search-label">Other Metadata </p>
                <TextArea
                  style={{ width: "100%" }}
                  label="Other Metadata"
                  validateStatus={validMetaData ? "" : "error"}
                  help={validMetaData ? "" : "Invalid Other Metadata"}
                  rows={8}
                  value={metaData}
                  onChange={(e) => {
                    setMetaData(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Form.Item style={{ marginTop: "1rem", marginBottom: "0rem", textAlign: "end" }}>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                Add
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddGroupModal;
