import { Button, Col, Form, Input, Row, Select, Tooltip, message, Radio, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  REACT_APP_API_DID_URL,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
  AUTH_TOKEN,
  REACT_APP_PAM_API,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { logOutUser } from "../../common";

export default function AddSecret() {
  const [isLoading, setIsLoading] = useState(false);
  const [secretType, setSecretType] = useState("password");
  const [notification, setNotification] = message.useMessage();
  const [issuerId, setIssuerId] = useState(null);
  const [walletType, setWalletType] = useState("");
  const [generateSSHPair, setGenerateSSHPair] = useState();
  const [SSHKeyPair, setSSHKeyPair] = useState({});
  const [hostNameList, setHostNameList] = useState([]);
  const [epmUsersList, setEpmUsersList] = useState([]);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchTenantVaultDetails();
    fetchIssuer();
    fetchHostname();
    fetchEPMUsers();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      publicKey: SSHKeyPair.publicKey ? SSHKeyPair.publicKey : "",
      secretSSH: SSHKeyPair.privateKey ? SSHKeyPair.privateKey : "",
    });
  }, [SSHKeyPair.publicKey, SSHKeyPair.privateKey, form]);

  const handleSubmit = async (e) => {
    let userId = localStorage.getItem("userId");
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
      issuerId: walletType === "Decentralized Wallet" ? issuerId : 0,
      userId: Number(userId),
      secretPassword: e.secretPassword
        ? e.secretPassword
        : generateSSHPair === 1
        ? SSHKeyPair.privateKey
        : e.userSecretSSH,

      epmusername: e.username,
      secrettype: e.secretType,
      secret_name: e.secretName,
      hostname: e.hostName,
      sshPublicKey: e.secretPassword
        ? ""
        : generateSSHPair === 1
        ? SSHKeyPair.publicKey
        : e.userPublicKey,
    };
    console.log(payload);
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${REACT_APP_API_DID_URL}/secretservice/createSecret`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      console.log(response.data);
      notification.open({
        type: "success",
        content: response.data.message,
      });
      setIsLoading(false);
      navigate("/pam/secrets");
    } catch (error) {
      setIsLoading(false);
      notification.open({
        type: "error",
        content: "Something went wrong. Please try again.",
      });
      console.log(error);
    }
  };

  const fetchTenantVaultDetails = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const res = await axios.post(
        `${REACT_APP_API_DID_URL}/secretservice/getVaulttypeandConfig`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      setWalletType(res.data.details?.vaulttype);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchIssuer = async () => {
    let payload = {
      orgId: ORG_ID(),
      tenantId: DOMAIN_ID(),
    };
    try {
      const response = await axios.post(`${REACT_APP_API_TENANTS_URL}/getDefaultIssuer`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      setIssuerId(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSSHKeyPairs = async (value) => {
    if (value === 1) {
      let payload = {};
      try {
        const res = await axios.post(
          `${REACT_APP_API_DID_URL}/secretservice/generateSSHPublicAndPrivateKey`,
          payload,
          {
            headers: {
              withCredentials: true,
              "X-Authorization": AUTH_TOKEN(),
            },
          }
        );
        let tempPair = {
          publicKey: res.data.publicKey,
          privateKey: res.data.privateKey,
        };
        setSSHKeyPair(tempPair);
      } catch (error) {
        notification.open({
          type: "error",
          content: "SSH key pair generate failed. Please try again.",
        });
        console.log(error);
      }
    }
  };

  const fetchHostname = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      MachineType: "endpoint",
    };
    axios
      .post(`${REACT_APP_PAM_API}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setHostNameList(res.data.instances);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch hostname",
          });
        }
      });
  };

  const fetchEPMUsers = () => {
    let payload = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoint-users",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setEpmUsersList(res.data.data.users);
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch users",
          });
        }
      });
  };

  return (
    <div>
      {setNotification}
      <Row className="content-conatiner">
        <Col span={24}>
          <h2 className="title">Add Secret</h2>
        </Col>

        <Col span={12}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              publicKey: SSHKeyPair.publicKey ? SSHKeyPair.publicKey : "",
              secretSSH: SSHKeyPair.privateKey ? SSHKeyPair.privateKey : "",
            }}
          >
            <Form.Item
              label="Secret Name"
              name="secretName"
              rules={[{ required: true, message: "Enter secret name" }]}
            >
              <Input type="text" placeholder="Enter secret name" />
            </Form.Item>

            <Form.Item
              label="Secret Type"
              name="secretType"
              rules={[{ required: true, message: "Enter secret type" }]}
            >
              <Select placeholder="Select secret type" onChange={(value) => setSecretType(value)}>
                <Select.Option value="Password">Password</Select.Option>
                <Select.Option value="SSH">SSH</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Select Hostname"
              name="hostName"
              rules={[{ required: true, message: "Select Hostname" }]}
            >
              <Select placeholder="Select Hostname">
                {hostNameList.map((hostname) => (
                  <Select.Option value={hostname.hostName}>
                    {hostname.hostName} <b>{`(${hostname.publicIp})`}</b>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Select User"
              name="username"
              rules={[{ required: true, message: "Select User" }]}
            >
              <Select placeholder="Select User">
                {epmUsersList.map((epmUser) => (
                  <Select.Option value={epmUser.user}>{epmUser.user}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            {secretType === "Password" ? (
              <Form.Item
                label={
                  <span>
                    Secret Password&nbsp;
                    <Tooltip
                      title={
                        <ul style={{ paddingLeft: "22px" }}>
                          <li>Password must contain at least one letter</li>
                          <li>Password must contain at least one number</li>
                          <li>Password must contain at least one special character</li>
                          <li>Password must be at least 8 characters long</li>
                        </ul>
                      }
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </span>
                }
                name="secretPassword"
                rules={[
                  { required: true, message: "Enter secret password" },
                  {
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    message:
                      "Password must contain at least one letter, one number, and one special character, and be at least 8 characters long.",
                  },
                ]}
              >
                <Input type="password" placeholder="Enter secret password" />
              </Form.Item>
            ) : (
              <>
                <Form.Item label="Generate SSH Key pair" name="generateSSHKeyPair">
                  <Radio.Group
                    onChange={(e) => {
                      fetchSSHKeyPairs(e.target.value);
                      setGenerateSSHPair(e.target.value);
                    }}
                  >
                    <Space>
                      <Radio value={1}>Generate SSH Key Pair</Radio>
                      <Radio value={2}>Upload your key pair</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>

                {generateSSHPair === 2 && (
                  <>
                    <Form.Item label="Public Key" name="userPublicKey">
                      <Input.TextArea placeholder="Enter Public Key" />
                    </Form.Item>

                    <Form.Item
                      label={
                        <span>
                          Private SSH Key&nbsp;
                          <Tooltip title="Please use a combination of letters, numbers, and symbols.">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      name="userSecretSSH"
                    >
                      <Input.TextArea placeholder="Enter private ssh key" />
                    </Form.Item>
                  </>
                )}

                {SSHKeyPair.privateKey && generateSSHPair === 1 && (
                  <>
                    <Form.Item label="Public Key" name="publicKey">
                      <Input.TextArea />
                    </Form.Item>

                    <Form.Item label="Private SSH Key" name="secretSSH">
                      <Input.TextArea placeholder="Enter private ssh key" />
                    </Form.Item>
                  </>
                )}
              </>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Add Secret
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
