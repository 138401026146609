import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Steps,
  DatePicker,
  TimePicker,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { AUTH_TOKEN, DOMAIN_ID, ORG_ID, REACT_APP_API_PAM_URL } from "../../../constants";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const POLICY_TYPES = [
  { value: "userGroupPolicy", label: "Group policy for a given user or groups" },
  { value: "serviceAccountPolicy", label: "Group policy for service account" },
  { value: "endpointUserPolicies", label: "Endpoint user policies" },
  { value: "databasePolicy", label: "Database policy" },
  { value: "networkDeviceAccessPolicies", label: "Network device access policies" },
];

const NETWORK_DEVICES = [
  { value: "device1", label: "Device 1" },
  { value: "device2", label: "Device 2" },
  { value: "device3", label: "Device 3" },
];

const DATABASE_TABLES = [
  { value: "table1", label: "Table 1" },
  { value: "table2", label: "Table 2" },
  { value: "table3", label: "Table 3" },
];

const CreatePolicyV4 = () => {
  const [form] = Form.useForm();
  const [notification, setNotificationHolder] = message.useMessage();
  const [current, setCurrent] = useState(0);

  const [policyType, setPolicyType] = useState("");
  const [endpointsData, setEndpointsData] = useState([]);
  const [endpointGroupsData, setEndpointGroupsData] = useState([]);
  const [adGroupsData, setAdGroupsData] = useState([]);
  const [endpointUsersData, setEndpointUsersData] = useState([]);

  useEffect(() => {
    if (policyType === "endpointUserPolicies") {
      fetchEndpointUsers();
      fetchEndpoints();
      fetchEndpointGroups();
    } else if (policyType === "userGroupPolicy" || policyType === "serviceAccountPolicy") {
      fetchADGroups();
      fetchEndpoints();
      fetchEndpointGroups();
    }
  }, [policyType]);

  const steps = [
    {
      title: "Step 1",
      content: (
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="What kind of policy would you like to create?"
                  name="policyType"
                  rules={[{ required: true, message: "Please select a policy type" }]}
                >
                  <Select
                    placeholder="Select policy type"
                    onSelect={(value) => setPolicyType(value)}
                    allowClear
                  >
                    {POLICY_TYPES.map((policy, index) => (
                      <Option key={index} value={policy.value}>
                        {policy.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="What would you like to name this policy?"
                  name="policyName"
                  rules={[{ required: true, message: "Please enter the policy name" }]}
                >
                  <Input placeholder="Enter policy name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              {policyType === "userGroupPolicy" || policyType === "serviceAccountPolicy" ? (
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Groups &nbsp;
                        <Tooltip title="Select one or more active directory groups.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectGroups"
                    rules={[{ required: true, message: "Please select a group" }]}
                  >
                    <Select mode="multiple" placeholder="Select group">
                      {adGroupsData.map((group, index) => (
                        <Option key={index} value={group.value}>
                          {group.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : policyType === "endpointUserPolicies" ? (
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Users &nbsp;
                        <Tooltip title="Select one or more endpoint users.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectEndpointUsers"
                    rules={[{ required: true, message: "Please select endpoint users" }]}
                  >
                    <Select mode="multiple" placeholder="Select endpoint users">
                      {endpointUsersData.map((user, index) => (
                        <Option key={index} value={user.value}>
                          {user.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : policyType === "networkDeviceAccessPolicies" ? (
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Network Devices &nbsp;
                        <Tooltip title="Select one or more network devices.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectNetworkDevices"
                    rules={[{ required: true, message: "Please select devices" }]}
                  >
                    <Select mode="multiple" placeholder="Select network devices">
                      {NETWORK_DEVICES.map((device, index) => (
                        <Option key={index} value={device.value}>
                          {device.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : policyType === "databasePolicy" ? (
                <Col span={24}>
                  <Form.Item
                    label={
                      <span>
                        Select Database Tables &nbsp;
                        <Tooltip title="Select one or more tables.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectDatabaseTables"
                    rules={[{ required: true, message: "Please select database tables" }]}
                  >
                    <Select mode="multiple" placeholder="Select database tables">
                      {DATABASE_TABLES.map((table, index) => (
                        <Option key={index} value={table.value}>
                          {table.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            {(policyType === "userGroupPolicy" ||
              policyType === "serviceAccountPolicy" ||
              policyType === "endpointUserPolicies") && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoints &nbsp;
                        <Tooltip title="Select one or more endpoints.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectEndpoints"
                    rules={[{ required: true, message: "Please select endpoints" }]}
                  >
                    <Select mode="multiple" placeholder="Select endpoint">
                      {endpointsData.map((endpoint, index) => (
                        <Option key={index} value={endpoint.value}>
                          {`${endpoint.label} (${endpoint.value})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <span>
                        Select Endpoint Groups &nbsp;
                        <Tooltip title="Select one or more endpoint groups.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                    }
                    name="selectEndpointGroups"
                    rules={[{ required: true, message: "Please select an endpoint group" }]}
                  >
                    <Select mode="multiple" placeholder="Select Endpoint group">
                      {endpointGroupsData.map((group, index) => (
                        <Option key={index} value={group.value}>
                          {`${group.label} (${group.value})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: "Step 2",
      content: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Access Date"
              name="accessDate"
              rules={[{ required: true, message: "Please select the access date" }]}
            >
              <DatePicker placeholder="Select date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Access Time"
              name="accessTime"
              rules={[{ required: true, message: "Please select the access time" }]}
            >
              <TimePicker.RangePicker format="HH:mm" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="recurringAccess" valuePropName="checked">
              <Checkbox>Recurring Access</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Frequency"
              name="recurrenceFrequency"
              rules={[{ required: true, message: "Please select recurrence frequency" }]}
            >
              <Select placeholder="Select frequency">
                <Option value="daily">Daily</Option>
                <Option value="weekly">Weekly</Option>
                <Option value="monthly">Monthly</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: "Step 3",
      content: (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Effective Date"
              name="effectiveDate"
              rules={[{ required: true, message: "Please enter the effective date" }]}
            >
              <Input placeholder="Enter effective date" type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Expiration Date"
              name="expirationDate"
              rules={[{ required: true, message: "Please enter the expiration date" }]}
            >
              <Input placeholder="Enter expiration date" type="date" />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  const next = async () => {
    try {
      await form.validateFields();
      setCurrent(current + 1);
    } catch (error) {
      // Validation failed
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleFinish = (values) => {
    message.success("Processing complete!");
    console.log("Form Values:", values);
  };

  const fetchADGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageId: 1,
        pageSize: 100,
        instanceId: 0,
        Filter: {
          filterBy: "adGroups",
          value: "",
        },
      };
      const res = await axios.post(`${REACT_APP_API_PAM_URL}/groups/listAll`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.groups.map((group) => {
        return {
          id: group.id,
          value: group.id,
          label: group.groupName,
        };
      });
      setAdGroupsData(tempData);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointGroups = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: `${DOMAIN_ID()}`, // fix: ask backend team to make this a integer
        pageId: 1,
        pageSize: 100,
        token: AUTH_TOKEN(),
        search: "",
        filter: {
          filterBy: "",
          value: "",
        },
      };
      const res = await axios.post(
        `${REACT_APP_API_PAM_URL}/instanceGroup/listEndpointGroup`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.groups.map((group, index) => {
        return {
          id: index + 1,
          value: group.hostName,
          label: group.groupName,
        };
      });
      setEndpointGroupsData(tempData);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpoints = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageId: 1,
        pageSize: 100,
        search: "",
        filter: {
          filterBy: "",
          value: "",
        },
        token: AUTH_TOKEN(),
      };
      const res = await axios.post(`${REACT_APP_API_PAM_URL}/instances/list`, payload, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      });
      const tempData = res?.data?.instances.map((endpoint, index) => {
        return {
          id: index + 1,
          value: endpoint.publicIp,
          label: endpoint.instanceName,
        };
      });
      setEndpointsData(tempData);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  const fetchEndpointUsers = async () => {
    try {
      let payload = {
        orgId: ORG_ID(),
        domainId: DOMAIN_ID(),
        pageId: 1,
        pageSize: 100,
        search: "",
        filter: {
          filterBy: "",
          value: "",
        },
        token: AUTH_TOKEN(),
        instanceIds: [],
      };
      const res = await axios.post(
        `${REACT_APP_API_PAM_URL}/endpointUsers/listAllEpmUsers`,
        payload,
        {
          headers: {
            withCredentials: true,
            "X-Authorization": AUTH_TOKEN(),
          },
        }
      );
      const tempData = res?.data?.epmUsers.map((user, index) => {
        return {
          id: index + 1,
          value: user.userId,
          label: user.userName,
        };
      });
      setEndpointUsersData(tempData);
    } catch (error) {
      console.log(error.message);
      notification.open({
        type: "error",
        content: error.message,
      });
    }
  };

  return (
    <Row className="content-container">
      {setNotificationHolder}
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <h2 className="title">Create Policy</h2>
          </Col>
        </Row>
      </Col>
      <Col span={18}>
        <Row>
          <Col span={24}>
            <Steps current={current}>
              {steps.map((item, index) => (
                <Steps.Step key={index} title={item.title} />
              ))}
            </Steps>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              style={{ marginTop: "2rem" }}
            >
              <div style={{ minHeight: "300px" }}>{steps[current].content}</div>
              <Row justify="end" style={{ marginTop: 24 }}>
                {current > 0 && (
                  <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button type="primary" htmlType="submit">
                    Done
                  </Button>
                )}
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreatePolicyV4;
