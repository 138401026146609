import { Row, Col, Collapse } from "antd";
import React from "react";
import { ReactComponent as MicrosoftNPS } from "./../../assets/microsoftNPS.svg";
import { ReactComponent as CiscoISE } from "./../../assets/ciscoISE.svg";
import { ReactComponent as ClearPass } from "./../../assets/clearpass.svg";
import { ReactComponent as OpenSourceRadius } from "./../../assets/opensourceradius.svg";
import { Link } from "react-router-dom";

export default function OnboardRadiusDevices() {
  const devices = [
    {
      name: "Microsoft NPS",
      logo: <MicrosoftNPS style={{ width: "50px", height: "50px" }} />,
      description:
        "Microsoft Network Policy Server (NPS) allows you to create and enforce organization-wide network access policies for connection request authentication.",
      link: "https://help.authnull.com/docs/radius/",
    },
    {
      name: "Cisco ISE",
      logo: <CiscoISE style={{ width: "50px", height: "50px" }} />,
      description:
        "Cisco Identity Services Engine (ISE) is a security policy management platform that provides secure access to network resources.",
      link: "https://help.authnull.com/docs/radius/",
    },
    {
      name: "Clearpass",
      logo: <ClearPass style={{ width: "80px", height: "40px" }} />,
      description:
        "ClearPass provides role- and device-based network access control for employees, contractors, and guests across any multivendor wired, wireless, and VPN infrastructure.",
      link: "https://help.authnull.com/docs/radius/",
    },
    {
      name: "Open Source RADIUS",
      logo: <OpenSourceRadius style={{ width: "50px", height: "50px" }} />,
      description:
        "Open Source RADIUS servers like FreeRADIUS offer flexible and customizable solutions for network authentication and accounting.",
      link: "https://help.authnull.com/docs/radius/",
    },
  ];

  const faq = [
    {
      key: "1",
      label: "What is a RADIUS server, and why is it important?",
      children: (
        <p>
          A RADIUS server (Remote Authentication Dial-In User Service) is a protocol that provides
          centralized Authentication, Authorization, and Accounting (AAA) management for users
          connecting to a network. It ensures secure access control for wired, wireless, and VPN
          infrastructures.
        </p>
      ),
    },
    {
      key: "2",
      label: "What are the prerequisites for setting up a Microsoft NPS server?",
      children: (
        <p>
          To set up Microsoft Network Policy Server (NPS): - Ensure Windows Server is installed. -
          Install the Network Policy and Access Services (NPAS) role. - Configure Active Directory
          to handle authentication requests. - Define RADIUS clients and policies in the NPS
          management console.
        </p>
      ),
    },
    {
      key: "3",
      label: "Can I use multiple RADIUS servers in a single network?",
      children: (
        <p>
          Yes, you can use multiple RADIUS servers to: - Improve redundancy and load balancing. -
          Provide localized authentication in geographically distributed networks. - Enhance
          reliability by having backup servers in case of a primary server failure.
        </p>
      ),
    },
  ];

  return (
    <>
      <Row className="content-conatiner">
        <Col span={24}>
          <Row>
            <Col>
              <h2 className="title">Add a new Radius Devices</h2>
            </Col>
          </Row>

          <Row style={{ marginBottom: "1rem" }}>
            <p> Select the device you want to connect to?</p>
          </Row>

          <Row gutter={24}>
            {devices.map((device) => (
              <Col span={6}>
                <div
                  style={{
                    backgroundColor: "#dbeeff",
                    height: "350px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: "30px",
                    borderRadius: "10px",
                  }}
                >
                  <div>
                    <div> {device.logo}</div>
                    <h1 style={{ fontSize: "18px", marginTop: "30px" }}> {device.name} </h1>
                    <p>{device.description} </p>
                  </div>
                  <div style={{ marginBottom: "1rem" }}>
                    <Link to={device.link}>Proceed to Setup</Link>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <Row style={{ marginTop: "3rem", marginBottom: "3rem" }}>
            <Col span={24}>
              <h3>FAQ</h3>
            </Col>
            <Col span={14}>
              <Collapse items={faq} bordered={false} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
