import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import {
  AUTH_TOKEN,
  REACT_APP_API_DID_URL,
  REACT_APP_API_PAM_URL,
  REACT_APP_PAM_API,
  ORG_ID,
  DOMAIN_ID,
  REACT_APP_API_TENANTS_URL,
} from "../../../constants";
import { logOutUser } from "../../../common";

export default function CheckoutUser() {
  const [selectedPort, setSelectedPort] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedIssuer, setSelectedIssuer] = useState("");
  const [selectedTtl, setSelectedTtl] = useState("30 Days");
  const [selectedProtocol, setSelectedProtocol] = useState();
  const [issuers, setIssuers] = useState([]);
  const [walletUsers, setWalletUsers] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [vcExpiry, setVcExpiry] = useState("");
  const [notification, setNotification] = message.useMessage();
  const [jumpServerData, setJumpServerData] = useState([]);
  const [checkWalletNotification, setCheckWalletNotification] = useState(false);
  const [selectedJumpServer, setSelectedJumpServer] = useState(null);
  const [checkoutStatus, setCheckoutStatus] = useState(false);
  const [osList, setOsList] = useState([]);
  const location = useLocation();
  const { instanceName, epmUsername, instanceId, os, epmUserId } = location.state || {};

  const Storage = localStorage.getItem("epmUser");
  const storedValue = JSON.parse(Storage);

  const checkOs = os ? os : storedValue.os;
  const checkInstanceId = instanceId ? instanceId : storedValue.instanceId;
  const CheckEpmUsername = epmUsername ? epmUsername : storedValue.epmUsername;
  const CheckInstanceName = instanceName ? instanceName : storedValue.instanceName;
  const CheckEpmUserId = epmUserId ? epmUserId : storedValue.epmUserId;

  const [formData, setFormData] = useState({});

  const protocolPortMap = {
    SSH: 22,
    RDP: 3389,
    VNC: 5900,
    Telnet: 22,
  };
  // useEffect(() => {
  //   let salVal = osList === "windows" ? "RDP" : "SSH";
  //   setSelectedProtocol(salVal);
  // }, []);
  useEffect(() => {
    fetchWallet();
    fetchIssuerList();
    fetchJumpserver();
    fetchOs();

    const initialValues = {
      selectTTL: "30 Days",
      selectprotocol: checkOs === "windows" ? "RDP" : "SSH",
      selectport: checkOs === "windows" ? 3389 : 22,
    };
    setSelectedPort(checkOs === "windows" ? 3389 : 22);
    form.setFieldsValue(initialValues);
    setFormData({
      selectTTL: "30 Days",
      selectprotocol: checkOs === "windows" ? "RDP" : "SSH",
      selectport: checkOs === "windows" ? 3389 : 22,
    });
  }, []);

  const fetchOs = () => {
    let osRequest = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
      entityType: "endpoints",
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/listEntity`, osRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        let os = res?.data?.data?.endpoints || [];
        let result = os?.find((instance) => Number(instance.id) === Number(checkInstanceId));
        fetchOsName(result?.osId);

        // if (result) {
        //   let os = result?.osId === "1" ? "windows" : "Linux";
        //   setOsList(result?.osId === "1" ? "windows" : "Linux");
        //   setSelectedPort(os === "windows" ? "3389" : "22");
        //   setFormData({
        //     selectprotocol: os === "windows" ? "RDP" : "SSH",
        //     selectport: os === "windows" ? "3389" : "22",
        //   });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchOsName = (val) => {
    let osNameRequest = {
      tenantId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_TENANTS_URL}/getOsList`, osNameRequest, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then(({ data }) => {
        console.log(data?.osList);
        let result = data?.osList?.find((os) => os.id === Number(val));
        console.log(data?.osList, val);

        let os = result?.osName.toLowerCase();
        setOsList(result?.osName);
        setSelectedPort(os === "windows" ? "3389" : "22");
        setSelectedProtocol(os === "windows" ? "RDP" : "SSH");
        setFormData({
          selectprotocol: os === "windows" ? "RDP" : "SSH",
          selectport: os === "windows" ? "3389" : "22",
        });
        form.setFieldsValue({
          selectprotocol: os === "windows" ? "RDP" : "SSH",
          selectport: os === "windows" ? "3389" : "22",
        });
      });
  };

  const fetchJumpserver = () => {
    let pageDetails = {
      pageId: 1,
      pageSize: 100,
      search: "",
      filter: {
        filterBy: "",
        value: "",
      },
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
      domainId: DOMAIN_ID(),
    };
    axios
      .post(`${REACT_APP_PAM_API}/jumpserver/listJumpServer`, pageDetails, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setJumpServerData(
          res?.data?.jumpServers.map((data) => ({
            value: data.id,
            label: data.serverName,
          }))
        );
      })
      .catch((err) => {
        if (
          err?.response?.data?.message === "Invalid credentials" ||
          err?.response?.status === 401
        ) {
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to fetch jump server",
          });
        }
      });
  };

  const fetchWallet = () => {
    let payload = {
      domainId: DOMAIN_ID(),
      pageNumber: 1,
      pageSize: 100,
      filter: "",
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/walletService/walletUserList`, payload, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res);
        setWalletUsers(
          res?.data?.users?.map((user) => ({
            value: user.walletId,
            label: user.emailAddress ? user.emailAddress : "-",
          }))
        );
      });
  };
  const fetchIssuerList = () => {
    let requestData = {
      domainId: DOMAIN_ID(),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_DID_URL}/did/issuerList`, requestData, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        setIssuers(
          res?.data?.map((data) => ({
            id: data.IssuerId,
            text: data.Name,
          })) || []
        );
      });
  };

  let insId = [];
  insId.push(Number(checkInstanceId));
  const handleUserCheckout = async () => {
    let data = {
      vcExpiry,
      domainId: DOMAIN_ID(),
      protocol: selectedProtocol,
      port: Number(selectedPort),
      epmUserId: Number(CheckEpmUserId),
      instanceId: insId,
      privilegedUser: true,
      epmCredentialExpiry: 1,
      accessCredential: false,
      assignmentTimeLimit: selectedTtl,
      shareConnection: true,
      issueVerifiedCredential: true,
      walletEmail: selectedWallet,
      issuerId: Number(selectedIssuer),
      jumpServerId: Number(selectedJumpServer),
      token: AUTH_TOKEN(),
      orgId: ORG_ID(),
    };
    setLoading(true);

    axios
      .post(`${REACT_APP_API_PAM_URL}/checkouts/assignedUserCheckout`, data, {
        headers: {
          "X-Authorization": AUTH_TOKEN(),
          withCredentials: true,
        },
      })
      .then((res) => {
        console.log(res.data.code);
        setLoading(false);
        notification.open({
          type: "success",
          content: "Checkout jobs created successfully",
        });
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to checkout now, PLease try again",
          });
        }
      });
  };

  const handleFinish = () => {
    let payloadData = {
      domainId: DOMAIN_ID(),
      epmUserId: Number(CheckEpmUserId),
      walletEmail: selectedWallet,
      protocol: selectedProtocol,
      instanceId: Number(checkInstanceId),
      orgId: ORG_ID(),
    };
    axios
      .post(`${REACT_APP_API_PAM_URL}/checkouts/pollCheckoutUser`, payloadData, {
        headers: {
          withCredentials: true,
          "X-Authorization": AUTH_TOKEN(),
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.code === 403) {
          if (res.data.status === "IN_PROGRESS") {
            setCheckoutStatus(true);
          } else {
            setCheckWalletNotification(true);
          }
        } else {
          handleUserCheckout();
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.message === "Invalid credentials") {
          notification.open({
            type: "error",
            content: "Credentials are invalid",
          });
          logOutUser();
        } else {
          notification.open({
            type: "error",
            content: "Unable to checkout now, PLease try again",
          });
        }
      });
  };

  return (
    <>
      {setNotification}
      {checkWalletNotification && (
        <Modal
          title={`Credentials have already been assigned to "${CheckEpmUsername}". The older credentials will be revoked, and new credentials will be provided.`}
          open={checkWalletNotification}
          onOk={() => {
            handleUserCheckout();
          }}
          onCancel={() => setCheckWalletNotification(false)}
          centered
        ></Modal>
      )}
      {checkoutStatus && (
        <Alert message={`Checkout is already In Progress`} type="info" showIcon closable />
      )}
      <Spin spinning={loading}>
        <Row className="content-conatiner">
          <Col span={24}>
            <Row>
              <Col>
                <h2 className="title">Checkout User</h2>
              </Col>
            </Row>

            <Form form={form} layout="vertical" onFinish={handleFinish}>
              <div>
                <Row justify="space-between">
                  <Col span={6}>
                    <Form.Item
                      initialValue={CheckEpmUsername}
                      name="endpoint-users"
                      label="Endpoint User"
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint User!",
                        },
                      ]}
                    >
                      <Input style={{ color: "#616161", backgroundColor: "#f5f5f5" }} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name="endpoints"
                      label="Endpoint"
                      initialValue={CheckInstanceName}
                      rules={[
                        {
                          required: true,
                          message: "Select Endpoint User!",
                        },
                      ]}
                    >
                      <Input
                        style={{ color: "#616161", backgroundColor: "#f5f5f5" }}
                        disabled={true}
                        defaultValue={CheckInstanceName}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      name="selectTTL"
                      label="Assignment Time Limit"
                      rules={[
                        {
                          required: true,
                          message: "Select TTL",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        value={formData.selectTTL}
                        options={[
                          { value: 0, label: "No Limit" },
                          { value: 1, label: "1 Day" },
                          { value: 30, label: "30 Days" },
                          { value: "custom", label: "Enter Custom Value" },
                        ]}
                        labelInValue={true}
                        onChange={(option) => setSelectedTtl(option.label)}
                      />
                    </Form.Item>
                    {selectedTtl === "Enter Custom Value" && (
                      <Form.Item
                        name="select-custom-date"
                        label="Custom Date"
                        rules={[
                          {
                            required: true,
                            message: "Select Expiry",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100% " }}
                          onChange={(_date, dateString) => setVcExpiry(dateString)}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </div>

              <Row
                style={{
                  backgroundColor: "rgb(223 223 224 / 40%)",
                  padding: "1rem 0.5rem",
                  marginTop: "2rem",
                }}
              >
                <Col span={24}>
                  <Row>
                    <span
                      style={{ margin: "0rem 0rem 1rem 10px", fontWeight: "500" }}
                      className="para-title"
                    >
                      Share connection information
                    </span>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col span={6}>
                      <Form.Item
                        name="selectprotocol"
                        label="Protocol"
                        rules={[
                          {
                            required: true,
                            message: "Select Protocol",
                          },
                        ]}
                        initialValue={formData.selectprotocol}
                      >
                        <Select
                          showSearch
                          labelInValue={true}
                          placeholder="Select Protocol"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={(option) => {
                            setSelectedProtocol(option.label);
                            setSelectedPort(protocolPortMap[option.value]);
                            form.setFieldValue("selectport", protocolPortMap[option.value]);
                          }}
                          options={[
                            { value: "SSH", label: "SSH", port: 22 },
                            { value: "RDP", label: "RDP", port: 3389 },
                            { value: "VNC", label: "VNC", port: 5900 },
                            { value: "Telnet", label: "Telnet", port: 22 },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="selectport"
                        label="Port"
                        rules={[
                          {
                            required: true,
                            message: "Select Port",
                          },
                        ]}
                      >
                        <Input
                          disabled={true}
                          style={{ color: "#616161", backgroundColor: "DAD8D8" }}
                          value={selectedPort}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="jumpserverId"
                        label="Select Jumpserver"
                        rules={[
                          {
                            required: true,
                            message: "Select jumpserver",
                          },
                        ]}
                      >
                        <Select
                          placeholder="select jumpserver"
                          onChange={(value) => setSelectedJumpServer(value)}
                          tokenSeparators={[","]}
                          showSearch
                          optionFilterProp="label"
                          options={jumpServerData}
                        />
                      </Form.Item>
                    </Col>{" "}
                  </Row>
                </Col>
              </Row>

              <Row
                style={{
                  backgroundColor: "rgb(223 223 223 / 35%)",
                  padding: "1rem 0.5rem",
                  marginTop: "2rem",
                }}
              >
                <Col span={24}>
                  <Row>
                    <span
                      style={{ margin: "0rem 0rem 1rem 10px", fontWeight: "500" }}
                      className="para-title"
                    >
                      Assign To ?
                    </span>
                  </Row>
                  <Row align="middle" justify="space-between">
                    <Col span={6}>
                      <Form.Item
                        name="userwallet"
                        label="User Wallet"
                        rules={[
                          {
                            required: true,
                            message: "Select User Wallet",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Wallet User"
                          showSearch
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(option) => setSelectedWallet(option.label)}
                          options={walletUsers}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name="select-issuer"
                        label="Select Issuer"
                        rules={[
                          {
                            required: true,
                            message: "Select Issuer",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select Issuer"
                          labelInValue={true}
                          optionFilterProp="children"
                          onChange={(option) => setSelectedIssuer(option.value)}
                          options={issuers.map((issuer, i) => ({
                            value: issuer.id,
                            label: issuer.text,
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="end" style={{ marginTop: "3rem" }}>
                <Col span={2}>
                  <Button type="default" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </Col>
                <Col span={2}>
                  <Button htmlType="submit" type="primary" disabled={checkoutStatus}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Spin>
    </>
  );
}
